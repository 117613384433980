/* Extra large desktop screens */
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .landing_page_titles {
    width: 25%;
    margin-left: 10px;
    height: 74vh;
  }
  .signatory_logo {
    height: 46.5vh;
  }
  .signatory_image {
    width: 11.3vw;
    cursor: pointer;
  }
  .exposing-fake-news {
    margin-top: 35vh !important;
  }
  .single_fact_check_page_second_col_antibiotic {
    margin-left: 10%;
    width: 75% !important;
  }
  .single_fact_check_image {
    width: 90% !important;
  }
  .top-content-fact-check {
    width: 100% !important;
  }
  .fact-check-rectangle-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 68.5vh;
  }
  .laptop-fact-check-categories {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .laptop-post-input {
    width: 48.5vw;
  }
  .feature-story-sub-title-content {
    color: #140f2d;
    font-size: 2vh;
    margin-top: 2vh;
    line-height: 4vh;
  }
  .single-fact-check-comment-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .single-fact-check-fake-image-div {
    height: 20vh;
    width: 18.5vw;
  }
  .single-fact-check-comment-extra {
    width: 100%;
  }
  .article_list {
    gap: 1.5vw !important;
  }
  .subtitle_text {
    height: 22vh;
    align-items: flex-start;
    display: flex;
    line-height: 3.1vh;
    overflow: hidden;
    margin-top: 9px;

    /* background-color: pink; */
  }
  .exposive_subtitle_text {
    line-height: 2.7vh;
    height: 16vh !important;
    align-items: flex-start;
  }

  .fact_check_rectangle_card {
    height: 14vh !important;
  }
  .rectangle_small_card_image {
    height: 14vh !important;
  }

  .laptop-report-donatio-row {
    width: 88%;
    gap: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    /* height: 38vh; */
  }

  .single-fact-check-report-card {
    /* height: 38vh; */
    width: 50vw;
    background-color: white;
    border-radius: 20px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .single-fact-check-mick-image {
    /* height: 123px; */
    width: 12.1vw !important;
  }
  .fact_check_donation_card {
    /* height: 38vh !important; */
  }
  .landing-page-user-contributed-stories {
    margin-right: 100px !important;
  }
  .part-wise-stories-secondary-container {
    gap: 17vh !important;
  }
  .custom_language_text_vertical {
    height: 12.6vh !important;
  }

  .sign-up-title-div {
    height: 10vh;
    width: 9.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .videos_page_card {
    border-radius: 6px;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
    width: 50vw;
    height: 37vh;
  }

  .video_page_card_content {
    width: 25vw;
    height: 37vh;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .videos_page_card_image {
    border-radius: 14px 14px 0 0 !important;
    object-fit: contain;
  }

  .donation-card-small {
    height: 79vh;
  }
  .donation-and-text-small-card {
    height: 52vh;
  }
  .donation-content-middle-container {
    height: 8vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .donation-content {
    color: white;
    font-size: 2.4vh !important;
  }

  .donation-content-small-card {
    color: white;
    font-size: 2.2vh !important;
  }

  .donation-content-small-card-text {
    color: white;
    font-size: 2.3vh !important;
  }

  .single-fact-check-comment-mobile {
    font-size: 2vh !important;
    line-height: 4vh !important;
  }

  .single-fact-check-child-reply-comments-text {
    font-size: 2vh !important;
    line-height: 4vh !important;
  }

  .icon_card_text_bold {
    font-size: 2.5vh;
    cursor: pointer;
    /* font-weight: bold !important; */
  }

  .fact-check-clock-circle-image {
    width: 1.5vw;
    height: 2vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .single-fact-check-view-time-col {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: "center" !important;
    margin-left: 5px;
  }

  .single-fact-check-comment-full-div {
    display: flex;
    justify-content: center;
  }

  .single-fact-check-claim-column {
    height: 63vh;
    top: 24vh;
  }

  .youtube-page-views-text {
    font-size: 2.1vh !important;
    line-height: 3vh !important;
    font-weight: 400 !important;
  }

  .videos-page-recently-added-div {
    height: 110vh !important;
  }

  .video-check-bread-crumbs-div {
    height: 5vh;
  }

  .donation-main-content {
    color: #f49d36;
    font-size: 5vh;
    font-weight: bold;
    line-height: 7vh !important;
    margin-top: 25px;
    font-family: Kufam;
  }

  .donation-card-input {
    width: 100% !important;
    height: 5vh !important;
  }

  .donation-note-text {
    font-size: 1.6vh !important;
    line-height: 2vh;
  }

  .landing_page_user_contributor_text_title {
    /* font-size: 4.5vh !important; */
    line-height: 6vh !important;
  }

  .landing_page_top_contributed_profile {
    width: 3.5vw !important;
    height: 7vh !important;
    border-radius: 70% !important;
  }
  .single-fact-check-eye-icon {
    width: 2vw;
    height: 2vh;
  }

  .you-video-page-title-text {
    font-size: 2vh !important;
    line-height: 3vh !important;
  }

  .landing_page_title {
    font-size: 3.5vh !important;
  }

  .submit_your_claim {
    height: 35vh !important;
  }

  .laptop-join-us-card-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 25vh;
  }

  .landing_page_fact_check_text {
    font-size: 2.5vh !important;
    line-height: 3.5vh !important;
  }

  .you-turn-videos-card {
    width: 23vw;
    height: 45vh;
  }

  .you-turn-videos-card-image-first-div {
    height: 34vh;
  }
  .you-turn-videos-card-image-second-div {
    height: 10.9vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .you-turn-videos-card-views-div {
    height: 3.4vh;
    width: 22vw;
  }

  .you-turn-videos-card-title-div {
    height: 6.5vh;
    width: 22vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .videos-page-story-div {
    width: 88%;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .video_load_button_color {
    width: 23%;
  }
  .back_arrow {
    position: absolute;
    top: 10vh;
  }
  .sing_up_button_div {
    /* height: 6vh; */
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .single-fact-check-view-col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: "center" !important;
  }

  .update-user-profile-buttons-full-div {
    display: flex;
    justify-content: center;
    width: 30vw;
  }

  .update-user-profile-buttons-div {
    width: 16vw;
    display: flex;
    justify-content: space-between;
    gap: 6px;
  }

  .edit_user_back_button {
    background-color: white !important;
    color: black !important;
    width: 8vw !important;
    border-radius: 10px;
    height: 4.5vh !important;
    border: 1px solid black !important;
  }
  .edit_user_save_button {
    width: 8vw !important;
    border-radius: 10px;
    height: 4.5vh !important;
  }

  .user-and-sign-in-section {
    height: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  .drawer_guest_div {
    width: 16vw !important;
    height: 5vh;
    display: flex;
    flex-direction: row;
  }
  .drawer_profile_icon_div {
    width: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .drawer_profile_name_div {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .drawer_profile_button_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 10vh;
  }

  .landing_submit_button {
    width: 24vw !important;
  }
  .award_image {
    width: 29.2vw;
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.905);
    opacity: 0.8;
    overflow: hidden;
    object-fit: cover;
  }

  .award_card_content {
    width: 22vw;
  }
  .awards-div {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  /* .youtube_logo_div {
    width: 10%;
    margin-top: 26% !important;
    margin-left: 17%;
    cursor: pointer;
  } */

  .donation-small-card-fight-text {
    font-size: 4vh !important;
    line-height: 3.5vh !important;
  }

  .single-fact-check-report-card-text {
    font-size: 3.5vh !important;
    line-height: 4vh !important;
  }

  .single-fact-check-report-card-second-text {
    font-size: 2vh !important;
    line-height: 2.5vh !important;
  }

  .single-fact-check-report-button {
    height: 5vh !important;
  }
  .ifcn_container {
    width: 93%;
    display: flex;
    justify-content: space-between;
    /* margin-top: 40px; */
    padding: 50px;
    margin: 6.5vh;
  }
  .ifcn_text_content {
    font-size: 2.5vh !important;
  }
  .fact_categories_name {
    display: flex;
    align-items: flex-start;
    margin-left: 7%;
    margin-top: 20vh !important;
  }
  .funding_component {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    padding: 8% 0 0 0;
    margin-top: 15vh !important;
  }

  .continue_reading_vertical_card {
    font-size: 1.7vh !important;
  }

  .see_all_stories_button {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  .landing-author-text {
    font-size: 1.5vh;
  }
  .card-image-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .you_turn_presents {
    font-size: 23px !important;
  }
  /* .video_card{
    background-color: green;
    position:relative ;
height:40vh ;
width: 40vw;
cursor: pointer;
  }
  .you_turn_video_card_image{
width:40vw !important;
height: 40vh !important;
z-index: 1;
border-radius:10px ;
object-fit: cover !important;
  } */

  .custom_small_video_card {
    height: 17vh;
    /* width: 60vw; */
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .sign_in_button_div {
    /* height: 0vh;
    margin-Top: 11vh */
  }
  .sign_up_and_log_div {
    /* margin-top:8vh !important */
  }
  .login_button_and_logo_image {
    /* margin-top: 3vh !important; */
  }
  /* .your-custom-classname {
    background-color: transparent !important;
    color: white !important;
    width: 28vh !important;
  } */

  .landing-slot-card-title-text {
    line-height: 3.3vh !important;
  }

  .landing-youturn-article-card-title-text {
    font-size: 2vh;
    font-weight: 600;
    line-height: 2.5vh !important;
  }

  .landing-youturn-article-card-subtitle-text {
    font-size: 1.8vh;
    font-weight: 400 !important;
    line-height: 2.4vh !important;
  }

  .landing-youturn-article-card-subtitle-div {
    height: 19.5vh !important;
    width: 95%;
    overflow: hidden;
  }

  .user-contribute-fact-div {
    width: 100% !important;
    background-color: #140f2d !important;
    height: 93vh;
    border-radius: 15px;
    padding: 3vw;
  }
  .fact_check_rectangle_content {
    height: 14vh;
    width: 19vw;
  }
  .rectangle_small_card_first_half {
    height: 8.8vh;
    overflow: hidden;
  }
  .custom_rectangle_small_card_first_half {
    height: 4vh;
  }

  .custom_rectangle_small_card_second_half {
    height: 8vh;
    overflow: hidden;
  }

  .single_article_page_top_content {
    height: 78vh !important;
  }

  .landing-youturn-video-card-div {
    display: flex;
    flex-direction: column;
    height: 50vh;
    justify-content: space-between;
  }

  .related-fact-check-title-div {
    height: 10vh;
    overflow: hidden;
  }

  .related_fact_check_card_last_text {
    font-size: 1.8vh !important;
    line-height: 2.8vh !important;
  }
  .donation-card-div {
    bottom: 63px !important;
  }

  .youturn_videos_box {
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
  }

  .landing-videos-page-col {
    width: 52%;
    height: 70vh;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .landing-page-videos-first-col {
    width: 40%;
    height: 48vh;
    margin-top: -10vh;
  }

  .landing-page-videos-first-col-div {
    display: flex;
    flex-direction: row;
    height: 7vh;
    justify-content: space-between;
  }

  .social-media-icons {
    /* width: 21%; */
    display: flex;
    justify-content: flex-end;
    padding: 6px;
  }

  .social-media-logo-container {
    display: flex;
    justify-content: space-between;
    width: 16.5vw;
  }
  .segment-container {
    display: flex;
    align-items: center;
    height: 6vh;
  }
  .segmented-option {
    height: 3vh !important;
  }
  .main-menu {
    color: white !important;
    width: 18vw;
    height: 3vh !important;
    background-color: transparent;
    display: flex;
    justify-content: end;
  }
  .screen-reader {
    padding: 0 5% 0 6% !important;
  }

  .social-media-container-big {
    width: 100%;
  }
  .font-weight {
    font-weight: 600;
  }

  .claim-news {
    font-size: 2.5vh;
  }
  .fact-check-content {
    font-size: 2.5vh !important;
    font-weight: 500 !important;
    text-align: justify;
  }
  .article-content {
    text-align: justify !important;
  }

  .terms_points {
    font-weight: 400 !important;
    line-height: 28px !important;
  }
  .contact_us_text {
    margin-bottom: 10px !important;
    font-weight: bold !important;
  }

  .landing-article-card-author-text {
    font-size: 1.4vh !important;
  }

  .card-heading {
    height: 41vh !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .single_article_page_text {
    width: 70vh;
    padding: 0 0 0 3% !important;
  }

  .tamil-about-description {
    color: white !important;
    font-size: 18px !important;
  }

  .donation-input-div {
    width: 26vw;
    height: 6vh;
  }

  .single_article_logo_text {
    margin-top: 45px;
  }
  .user_contribution_headline {
    height: 18vh;
    cursor: pointer !important;
    overflow: hidden;
    /* background-color: antiquewhite; */
    display: flex;
    align-items: center;
  }
  .main-menu :hover {
    color: #f49d36 !important;
  }
  .mobile_header_drawer {
    background: linear-gradient(166.4deg, #140f2d 9.74%, #f49d36 281.54%);
    height: -webkit-fill-available;
    width: 100%;
  }
  .user_contribution_join_div {
    display: none !important;
  }
  .public_spirited_image_image_and_text_div {
    height: 374px;
    display: flex;
    width: 67%;
    justify-content: center;
    flex-direction: column;
  }
  .ifcn_logo {
    width: 40% !important;
    display: flex !important;
    gap: 221px !important;
    flex-direction: column !important;
  }
  .public_spirited_text_content {
    font-size: 22px !important;
    line-height: 30px !important;
  }
  .team_members_profile_card {
    display: flex;
    width: 100%;
    margin-bottom: 70px;
    gap: 183px;
    padding: 15px;
  }
  .about-us-header {
    /* width: 100%; */
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    gap: 25px;
    padding: 0 0 8% 0;
    height: auto !important;
  }
  .video_card {
    position: relative;
    height: 300px;
    width: 646px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  .subscribe_and_notification_icon_main_div {
    width: 83%;
    display: flex;
    justify-content: center;
  }
}
