.single_fact_check_report_donation_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;
}
.single_fact_check_report_donation_wrapper {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.related_recent_fact_check_container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.related_recent_fact_check_wrapper {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
}
.related_recent_fact_check_title_container {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.related_fact_check_title_container {
  /* height: 90px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
}
.recent_fact_check_title_container {
  /* height: 90px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
}
.related_recent_fact_check_card_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.related_fact_check_container {
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 30px;
  column-gap: 30px;
}
.recent_fact_check_container {
  height: 100% !important;
  width: 100% !important;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.related_fact_check_banner {
  width: 55%;
  display: flex;
  flex-direction: column;
}
.recent_fact_check_banner {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.recent_fact_check_card_container {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
  cursor: pointer;
}
.recent_fact_check_thumbnail {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.recent_fact_check_thumbnail_container {
  width: 35%;
  /* height: 100%; */
  object-fit: cover;
}
.recent_fact_check_inner_title_container {
  height: 100%;
  width: 65%;
  overflow: hidden;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.recent_fact_check_title_wrapper {
  width: 100%;
  overflow: hidden !important;
}

.single_fact_check_claim_content_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.single_fact_check_claim_content_wrapper {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.single_fact_check_claim_container {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
}
.single_fact_check_content_container {
  height: 100%;
  width: 65%;
}
.single_fact_claim_title {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.youTurn_rating_image {
  width: 50%;
  margin-top: 3%;
  margin-bottom: 10%;
}
.single_fact_check_rating_container {
  display: flex;
  justify-content: center !important;
  align-items: center;
  width: 100%;
}
.single_fact_check_whats_app_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.single_fact_details_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
}
.recent_fact_check_title_text {
  font-size: "Kufam" !important;
  font-weight: 600;
}
.report_modal {
  max-width: 600px;
  margin: auto;
  border-radius: 8px !important;
}

.report_modal_close_icon {
  font-size: 20px;
  color: white;
}

.report_modal_content {
  padding: 15px 15px 25px 15px;
}

.report_modal_footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.report_modal_submit_button {
  background-color: rgb(220, 53, 69);
  border-color: rgb(220, 53, 69);
  cursor: pointer;
  border: none;
}

.report_modal_submit_button:hover {
  background-color: rgb(220, 53, 69) !important;
  color: white !important ;
  border: none !important;
}
.single_fact_check_report_container {
  width: 58%;
  /* height: 100%; */
}

.whatsapp_channel_container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #00704a;
}

.whatsapp_channel_text {
  font-family: "Kufam";
  color: white;
}

.whatsapp_channel_icon {
  width: 75% !important;
  margin-right: 10px;
}

.fact_check_share_save_container {
  padding: 4% 0 4% 0;
}
.related_fact_check_title_container > h4 {
  font-family: "Kufam";
  font-weight: 800;
}
.single_fact_check_spread_news_container {
  margin-bottom: 20px;
}
.recent_fact_check_card_skeleton {
  height: 100% !important;
  width: 100% !important;
}
.single_fact_check_rating_skeleton {
  width: 100% !important;
  height: 100% !important;
}

.recent_fact_check_title_container > h4 {
  font-size: "Kufam" !important;
  font-weight: 600;
}
.single_fact_claim_title > h5 {
  font-weight: 800;
  font-family: "Kufam";
}
.single_fact_details_title > h5 {
  font-family: "Kufam";
  font-weight: 800;
}
.whatsapp_channel_text_en {
  font-family: "Kufam";
  color: white;
}
.recent_fact_check_title_text_ta {
  font-size: "Kufam" !important;
  font-weight: 600;
}
.report_modal.ant-modal .ant-modal-content {
  padding: 0px;
}
.report_modal_title {
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.report_modal_title_container {
  background-color: var(--background-theme-color);
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
}
.report_cancel_button {
  background-color: #30719c;
  border-color: #30719c;
  color: white;
  border: none !important;
}

.report_cancel_button:hover {
  background-color: #30719c !important;
  color: white !important;
  border: none !important;
}
.report_title_container{
  margin-bottom: 20px;
  text-align: center;
}

.fact-check-content{
  background-color: #ffffff !important;
}
.fact-check-content{
  width:100%
}
.video-content{
  width: 100%;
  height: auto;
}
