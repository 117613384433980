@media only screen and (min-width: 0px) and (max-width: 767px) {
  .related_recent_fact_check_container {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }
  .related_recent_fact_check_card_container {
    width: 100% !important;
    flex-direction: column !important;
  }
  .related_fact_check_banner {
    width: 100%;
  }

  .related_fact_check_container {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;
    margin-bottom: 15px;
  }
  .recent_fact_check_banner {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .recent_fact_check_title_text {
    font-size: 12px !important;
    line-height: 17px !important;
  }
  .single_fact_check_claim_content_wrapper {
    width: 90%;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
  }
  /* .recent_fact_check_title_wrapper {
    height: 36px;
  } */
  .home_user_contribution_two_profile {
    font-size: 10px;
  }
  .donation_first_row_content {
    font-size: 13px;
    line-height: 20px !important;
  }
  .single_fact_check_report_container {
    display: none !important;
  }
  .recent_fact_check_title_container > h4 {
    font-size: 13px !important;
  }
  /* .recent_fact_check_title_container { */
  /* height: 40px; */
  /* } */

  .related_fact_check_title_container > h4 {
    font-size: 13px !important;
  }
  .single_fact_claim_title > h5 {
    font-size: 13px !important;
  }
  .single_fact_details_title > h5 {
    font-size: 13px !important;
  }
  .single_fact_claim_title {
    justify-content: flex-start !important;
  }
  .comments_title_container > h5 {
    font-size: 13px;
  }
  .donation_first_row_content_en {
    font-size: 13px;
    line-height: 20px !important;
  }
  .recent_fact_check_title_text_ta {
    font-size: 10px !important;
    line-height: 17px !important;
  }
  .single_fact_check_rating_container {
    align-items: start;
    justify-content: flex-start !important;
  }

  .youTurn_rating_image {
    width: 50%;
    margin-bottom: 3%;
  }
  .single_fact_check_content_container {
    height: 100%;
    width: 100% !important;
  }
  .single_fact_check_whats_app_container {
    display: none !important;
  }
}
