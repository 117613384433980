/* Tablet styles (768px to 1023px) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .fact_check_party_wise_selector {
    background-color: #0065cc !important;
  }
  .fact_check_categories_list {
    display: none;
  }
  .fact_check_card_mobile {
    width: 140vh !important;
    height: 90vh !important;
    border-radius: 10px !important;
    z-index: 1;
  }
  .article_top_image {
    /* width: 100%;
    height: 60vh !important; */
    border-radius: 10px !important;
    z-index: 1;
    cursor: pointer;
  }

  .single_article_continue_reading {
    background-color: #0065cc;
    border: transparent;
    color: white;
    width: 310px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
  }
  .article_page_content_col {
    width: 90% !important;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .single_article_small_fact_check_card {
    margin-top: 40px;
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .single_article_related_fact_check_card {
    height: 520vh;
    margin-top: 70px;
  }
  .related_fact_check_card_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
    padding: 6% 0 0 0;
    /* background-color: gray; */
  }
  .single_article_recommended_stories {
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .single_article_fact_check_categories {
    width: 100%;
    height: 100%;
  }
  .fact_check_categories_sub_title {
    padding: 3%;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    height: 100px;
    font-size: 3.5vh;

    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.13);
  }
  .single_article_related_fact_check {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
  }
  .fact_check_category_card_image {
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .fact_check_category_card_image_div {
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .fact_check_category_card {
    width: 89% !important;
    /* height: 53vh !important; */
    position: relative;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.13);
    cursor: pointer;
    border-radius: 10px;
  }
  .related_fact_check_card_last_text {
    font-size: 3vh !important;
  }
  .fact_check_category_card_text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-color: rgba(20, 15, 45, 0.75);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3%;
  }
  .footer-first-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    margin-top: 25px;
  }
  .footer {
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
    background-color: #fafcff;
    padding: 20px;
  }
  .categories_selector {
    width: 80%;
  }

  .article_page {
    width: 100%;
    height: 70vh;
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    gap: 25px;
  }

  .fact_check_donation_card {
    width: 100% !important;
    border-radius: 0 !important;
    background-color: #140f2d;
  }
  .article_browse_categories {
    width: 100%;
    height: auto;
    margin-top: 50px;
    gap: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .media_share {
    display: none;
  }
  .category_wise_article {
    display: none;
  }
  .article_browse_categories_text {
    width: 100%;
    height: 10%;
    display: flex;
    padding: 0 0 4% 10%;
  }
  .article_featured_story {
    color: #f49d36;
    font-weight: bold;
    font-size: 4vh;
  }
  .article_title {
    margin-top: 20px;
  }
  .article_content {
    padding: 5% 0 0 0;
    width: 100%;
    height: auto;
    cursor: pointer;
    text-align: justify;
  }
  .article_sub_title {
    font-size: 3vh;
  }
  .article_continue_reading {
    color: #0065cc;
    text-decoration: underline;
    font-size: 2.5vh;
    font-weight: bold;
    cursor: pointer;
  }
  .article_continue_reading_div {
    /* height: 10vh; */
    padding: 4% 0 0 0;
  }

  .article_headline {
    margin-top: 10px !important;
    height: auto;
    cursor: pointer;
  }
  .article_headline_text {
    font-size: 15px;
    font-weight: bold;
    font-family: Lato;
  }
  .inner_article {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .article_donation_card {
    display: none;
  }
  .article {
    width: 90% !important;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .donation-card {
    width: auto;
    border-radius: 0% !important;
  }

  .recommended_stories_title_box {
    width: 100%;
  }
  .recommended_stories_text {
    display: flex;
    justify-content: column;
    height: 60px;
    align-items: flex-start;
  }
  .join_us_content {
    margin-top: 20px !important;
    font-size: 26px !important;
    /* padding: 0 5% 0 5%; */
    line-height: 35px !important;
    text-align: center;
  }
  .social-media-list {
    width: 330px !important;
  }
  .user_contribution {
    width: 100% !important;
    height: 300px !important;
  }
  .user_contribution_text {
    width: 100% !important;
    padding: 7% 7% 7% 7%;
  }
  .user_contribution_headline {
    margin-top: 10px !important;
  }

  .user_contribution_content {
    padding: "2% 0 0 0";
  }
  .user_contribution_img_with_content {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
  }
  .user_contribution_top_image {
    width: 100% !important;
  }
  .user_contribution_recently_added_component {
    margin-top: 470px;
  }
  .user_contribution_headline_text {
    color: #140f2d;
  }

  .recommended_stories_title {
    margin: 25px 0 0 30px;
  }
  .recommended_stories {
    width: 100% !important;
    height: auto !important;
    flex-direction: column;
    margin-top: 20px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 40px;
  }

  .report-card {
    width: 700px !important;
    height: 355px !important;
  }
  .big-screen {
    display: none !important;
  }
  .social-media {
    width: 100% !important;
  }
  .social-media-container-mobile {
    display: none !important;
  }

  .single_article_page_second_col_antibiotic {
    width: 100% !important;
    height: 80vh;
    display: flex;
    justify-content: center;
    background-color: red !important;
    /* margin-left: 25px; */
  }

  .landing_page_title {
    display: none !important;
  }
  .landing_page_news_story_card_vertical {
    display: none !important;
  }
  .landing_page_titles {
    height: 220px !important;
    width: 300px;
    align-items: center !important;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
  }
  /* .hide_content_in_desktop {
      display: none !important;
    } */

  .landing_page {
    background-color: #140f2d;
    z-index: 2;
    position: relative;
    padding: 20px;

    height: 155px !important;
    width: 100%;
    box-shadow: 0 20px 50px 30px #140f2d;
  }

  .signatory_image {
    width: 150px !important;
    margin-left: 900px !important;
    margin-top: -190px !important;
  }
  .you_turn_presents {
    font-size: 18px !important;
    margin-left: 55px;
    /* background-color: #0065cd; */
    margin-top: 50px;
  }
  .news_article_carousel_card {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .left_arrow_out_lined {
    font-size: 30px;
    color: #0065cc;
    cursor: pointer;
    margin-top: 60px;
  }
  .right_arrow_out_lined {
    font-size: 30px;
    color: #0065cc;
    cursor: pointer;
    margin-top: 55px;
  }
  .news_article_carousel {
    gap: 60px;
    display: flex;
    justify-content: center;
  }
  .news_article_carousel_text {
    width: 60%;
    justify-content: space-between;
    margin-top: 15px !important;
  }
  .carousel_title {
    width: 60%;
  }
  .carousel_text {
    font-size: 20px !important;
  }
  .exposing_fake_news_card {
    width: 100%;
    height: 300px;
  }
  .exposing_fake_news {
    height: 300px !important;
    width: 85% !important;
  }
  .landing_page_news_article {
    display: none !important;
    margin-left: 10px !important;
  }
  .rectangle_small_card_mobile_view {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 34px;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 120px !important;
  }

  .hide_content {
    display: none !important;
  }
  .fact_check_inside_card_mobile_view {
    width: 75% !important;
    background-color: rgba(20, 15, 45, 0.9);
    margin-top: 290px !important;
    z-index: 2;
    position: absolute;
  }

  .article_news_story_card_vertical {
    display: none;
  }

  .article_card_first_half {
    /* padding: 4% 0 0 0; */
  }
  .join_us {
    width: 100% !important;
    height: 230px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: linear-gradient(147.32deg, #140f2d 40.85%, #f49d36 169.52%);
  }
  .join_us_card {
    height: 180px;
    width: 70%;
  }
  .join_us_card_title_and_button {
    height: 230px !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .become_member_button {
    margin-top: 20px !important;
  }
  .join_us_button {
    width: 100%;
    border-radius: 9px;
    border: transparent;
  }
  .article_top_title {
    width: 100%;
    height: 112px;
    padding: 4%;
    background-color: #140f2d;
  }
  .categories_selector {
    width: 80%;
  }
  .article_browse_categories_title_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .carousel_card {
    width: 100%;
  }
  .article_carousel {
    background-color: #1e1745;
    height: 300px;
    margin-top: 40px;
  }
  .top_six_article_card {
    display: flex;
    width: 100%;
  }

  .top_six_article {
    width: 100%;
    margin-left: 10%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 3% 0 0 0;
    height: 26%;
  }
  .list-article-main-container {
    height: 30vw;
  }
  .list-article-image {
    width: 50vh !important;
  }

  .landing_page_mobile_view_title {
    width: 270px;
    margin-left: 65px;
    background: red;
  }
  .signatory_image {
    width: 150px !important;
    margin-left: 900px !important;
    margin-top: -190px !important;
  }
  .you_turn_presents {
    font-size: 18px !important;
    margin-left: 55px;
    /* background-color: #0065cd; */
    margin-top: 50px;
  }
  .news_article_carousel_card {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .article_title_size {
    font-size: 30px !important;
  }

  .left_arrow_out_lined {
    font-size: 30px;
    color: #0065cc;
    cursor: pointer;
    margin-top: 60px;
  }
  .right_arrow_out_lined {
    font-size: 30px;
    color: #0065cc;
    cursor: pointer;
    margin-top: 55px;
  }
  .news_article_carousel {
    gap: 60px;
    display: flex;
    justify-content: center;
  }
  .news_article_carousel_text {
    width: 60%;
    justify-content: space-between;
    margin-top: 15px !important;
  }
  .carousel_title {
    width: 60%;
  }
  .carousel_text {
    font-size: 20px !important;
  }
  .exposing_fake_news_card {
    width: 100%;
    height: 300px;
  }
  .exposing_fake_news {
    height: 300px !important;
    width: 85% !important;
  }
  .landing_page_news_article {
    display: none !important;
    margin-left: 10px !important;
  }
  .rectangle_small_card_mobile_view {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 34px;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 120px !important;
  }

  .categories_selector {
    width: 80%;
  }

  .carousel_card {
    width: 100%;
  }
  .article_carousel {
    background-color: #1e1745;
    height: 300px;
    margin-top: 40px;
  }

  .top_six_article {
    width: 100%;
    margin-left: 10%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 3% 0 0 0;
    height: 26%;
  }
  .list-article-main-container {
    height: 30vw;
  }
  .list-article-image {
    width: 50vh !important;
  }
  .your-row-class {
    height: 85vh !important;
    background-color: #140f2d;
  }
  .your-row-class .ant-col:first-child {
    order: 2;
  }

  .your-row-class .ant-col:last-child {
    order: 1;
  }
  .single_article_image {
    width: 100%;
  }
  .single-article-page-first_division {
    display: flex !important;
    flex-direction: column !important;
    padding: 5%;
    width: 100%;
    height: 170vh;
  }
  .single_article_page_top_content {
    height: 174vh;
  }
  .single_article_page_bread_crumbs {
    width: 100%;
    padding: 0 0 0 5%;
  }
  .single_article_page_first_col_antibiotic {
    width: 100% !important;
    /* background-color: yellow; */
  }
  .single_article_title_row {
    height: 150px;
    width: 100%;
    padding: 6% 0 0 0;
  }
  .single_article_report_share_icons {
    display: none;
  }
  .icon_card_image {
    width: 100px !important;
  }
  .icon_card .icon_card_first_col {
    order: 1 !important;
  }
  .single_article_view_icon {
    display: none !important;
  }
  .single_article_page_article_date {
    margin-top: 30px;
  }
  .article_page_content_row {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .single_article_donation_and_social_media {
    display: none !important;
  }
  .aligncenter {
    width: 100%;
  }
  .report_card {
    display: none !important;
  }
  .share_report_save {
    width: 100%;
    padding: 10% 0 5% 0;
  }
  .donation_card_small_view {
    margin-top: 30px;
  }
  .report_share_card {
    margin-top: 50px;
  }

  .single_article_title {
    color: #000000 !important;
    line-height: 30px !important;
  }
  .single_article_view_report_share {
    width: 100%;
  }
  .single_article_join_us_card {
    display: none;
  }
  .fact_check_rectangle_card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
    border-radius: 8px;
    height: 15vh !important;
  }
  .list_of_article_top_image {
    width: 100%;
    height: 90vh !important;
    border-radius: 10px !important;
    z-index: 1;
    cursor: pointer;
  }
  .list_of_article_inside_card {
    width: 75% !important;
    background-color: rgba(20, 15, 45, 0.9);
    margin-top: 290px !important;
    z-index: 2;
    position: absolute;
  }
  .fact_check_inside_card_width {
    height: 50px;
    margin: 4% 0 0 4%;
    background-color: red;
  }
  .fact_check_inside_card {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 4% 0 2% 4%;
    background-color: gray;
  }
  .view_count_and_icon {
    display: flex;
    flex-direction: row;
    gap: 3px;
  }
  .view_icon {
    color: #e84756;
    width: 5vh;
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }
  .fact_check_card_mobile_view {
    background-color: "red" !important;
    flex-direction: column !important;
  }
  .fact_check_inside_card_title {
    font-size: 2vh !important;
    width: 70vw;
    margin: auto;
  }
  .custom_fact_check_views_and_icon {
    color: #e84756;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    margin-bottom: 6px;
    margin-left: 2vh;
  }
  .article_recommended_stories_container {
    height: 90%;
    padding: 5% 5% 0 5%;
    justify-content: space-between;
  }
  .article_page_inside_card {
    width: 75% !important;
    background-color: rgba(20, 15, 45, 0.9);
    margin-top: 248px !important;
    z-index: 2;
    position: absolute;
  }
  .top_article_inside_card_width {
    height: 50px;
    margin: 4% 0 0 4%;
  }
  .article_inside_card_title {
    font-size: 15px !important;
    width: 100%;
    margin: auto;
  }
  .article_inside_card {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 4% 0 2% 4%;
  }
  .article_page_inside_card_title {
    font-size: 2vh !important;
  }
  .article_page_button {
    display: none !important;
  }
  .article_article_small_card {
    /* height: 100vh !important;
    display: flex;
    flex-direction: column;
    gap: 8vh; */
    display: none;
  }
  .rectangle_small_card_image {
    width: 30vw !important;
    height: 15vh !important;
  }
  .fact_check_rectangle_news {
    font-size: 2.2vh !important;
    font-weight: bold;
    line-height: 4vh !important;
  }
  .fact_check_rectangle_content {
    width: 60% !important;
  }

  .article_recommended_stories {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
  .join_us_card_image {
    display: none !important;
  }
  .see_all_article_button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .single_article_page_related_fact_check {
    margin-left: 40px;
  }
  .single_article_logo_text {
    margin-top: 20px;
  }
  .single_article_page_text {
    width: 100% !important;
    padding: 7% 7% 7% 7%;
  }

  .party_tag_vertical_and_small_card_div {
    display: flex;
    flex-direction: column;
  }
  .party-wise-update-fact-check-small-card {
    display: flex;
  }
  .fact-check-fist-half-row {
    margin: 10%;
  }
  .party_tag_vertical_and_small_card_div {
    display: flex;
    flex-direction: column;
  }
  .party-wise-update-fact-check-small-card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10vh;
    margin: 0 10% 0 10%;
  }
  .party-tag-news-card-vertical {
    display: none;
  }
  .fact-check-news-vertical-by-fist-half {
  }
  .donation-card-div {
    display: none !important;
  }
  .fact-check-join-us-card {
    margin-top: 18vh !important;
  }
  .browse_categories_text {
    width: 100% !important;
    margin-left: 10%;
    line-height: 4vh !important;
  }
  .top-content-fact-check {
    height: 90vh;
  }
  .fact-check-row-class {
    background-color: #140f2d;
    height: 38vh;
  }
  .fact-check-bread-crumbs-div {
    height: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2vh;
  }
  .fact-check-space-between {
    display: flex;
    flex-direction: column-reverse;
    padding: 2vh;
  }
  .single-fact-check-div {
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .report-share-icon-div {
    display: none;
  }

  .you-turn-logo-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 15vh;
  }
  .single-fact-check-you-turn-logo {
    width: 18vw !important;
  }
  .eye-image-div {
    display: none;
  }
  .fact-check-you-turn-logo-col {
    width: 27%;
  }
  .fact-check-date-views-col {
    width: 60%;
    height: 13vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .icon_card_text_bold {
    height: 6.5vh;
    font-size: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .icon_card_text {
    font-size: 3vh;
    height: 6.5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .single-fact-check-report-share-div {
    padding: 3vw;
    height: 15vh;
  }

  .single-fact-check-content-div {
    display: flex;
    justify-content: center;
  }
  .single_fact_check_explanation {
    width: 95%;
  }
  .single-fact-check-claim-column {
    width: 100%;
  }
  .single-fact-check-content-title-div {
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .single-fact-check-claim-div {
    height: 10vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .single-fact-check-content-title {
    height: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .single-fact-check-view_report_share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 10vh;
    padding: 3vw;
  }
  .single-fact-check-comment-full-div {
    display: none;
  }
  .single-fact-check-report-card {
    display: none;
  }
  .related-fact-check-row {
    /* display: flex;
    flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
  }
  .single-fact-check-related-fact-check-col {
    width: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }
  .single-fact-check-related-fact-ful-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .related-fact-check-title {
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4.5vh;
  }

  .laptop-fact-check-categories {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 5vh;
  }
  .single-fact-check-recently-added-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .fact-check-rectangle-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    gap: 5vh;
    padding: 5vw;
  }
  .mobile-donation-form {
    display: none;
  }
  .donation-input-div {
    height: 10vh;
    margin-top: 27px;
  }
  .single-fact-check-title {
    line-height: 4vh !important;
    height: 15vh;
    font-size: 3vh;
    font-weight: bold !important;
    overflow: hidden;
  }
  .search_result_topic_with_search_box {
    width: 100%;
  }
  .search_result_header_content {
    width: 100%;
    height: 38vh;
    padding: 4% 0 0 8%;
  }
  .recently_added_carousel {
    width: 40vw;
    height: 60vh;
    border-radius: 10px;
  }
  .recently_added_thumbnail_image {
    width: 40vw;
    height: 25vh;
    border-radius: 10px 10px 0 0;
  }
  .search_result_name_and_view_icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 3vh 3vh 0 3vh;
  }
  .recently_added_author_name_and_date {
    height: 6vh;
    align-items: center !important;
    display: flex;
    justify-content: center;
  }
  .search_result_view_eye_icon {
    width: 5vw;
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search_result_view_count_text {
    height: 6vh;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .search_result_reading_time_text {
    height: 6vh;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .recently_added_title {
    padding: 0 3vh 0 3vh;
    height: 12vh;
    max-height: 15vh !important;
  }
  .search_result_date_and_clock_icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 0 3vh 3vh 3vh;
  }
  .search_result_view_and_clock_icon_div {
    width: 13vw;
  }
  .search_result_bread_crumbs {
    display: none;
  }
  .search_bread_crumbs_with_topic {
    width: 80%;
    height: 15vh;
  }
  .search_input_and_button {
    display: none;
  }
  .selector_and_divider {
    height: 15vh;
    padding: 6% 0 0 8%;
  }
  .desk_menu_select_drop_down {
    width: 30vw;
  }
  .button_divider {
    width: 90%;
  }
  .search_result_news_story_card {
    display: none;
  }
  .search_result_rectangle_small_card {
    width: 85%;
  }
  .search_result_cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    gap: 7vh;
    width: 100%;
  }
  .search_results_items {
    width: 100%;
    padding-top: 5%;
  }
  .load_button_color {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 8px;
  }
  .load_more_button {
    width: 80%;
    padding-bottom: 10%;
  }
  .search_result_trending_now {
    width: 100%;
    gap: 5vh;
    align-items: center !important;
    padding-bottom: 5%;
  }

  .search_result_recently_added_first_card {
    width: 100%;
    padding-top: 8%;
  }
  .search_result_recently_added_carousel {
    padding-top: 10%;
  }

  .trending-now-arrow-image {
    display: none;
  }
  .search_result_social_media_sticky {
    display: none;
  }
  .search_result_recently_added {
    display: none;
  }

  .social_media_list_card {
    width: 100% !important;
    padding: 5px !important;

    background: linear-gradient(
      166.4deg,
      #140f2d 9.74%,
      #f49d36 281.54%
    ) !important;
    height: 76vh !important;
    border: transparent !important;
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.3) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .social_media_list_title {
    font-weight: bold;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 25px !important;
  }
  .social_media_logo {
    width: 80% !important;
    height: 25vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 6%;
  }
  .social_media_image_with_name {
    width: 20vw;
    height: 17vh;
  }
  .custom_social_media {
    width: 20vw;
    height: 13vh;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .search_result_news_article {
    display: flex;
    gap: 5vh;
  }
  .user_profile_card {
    height: 16vh !important;
    width: 80vw !important;
    background: linear-gradient(147.32deg, #140f2d 40.85%, #f49d36 169.52%);
    padding: 3%;
  }
  .user_profile {
    height: 8vh !important;
    width: 8vw;
    border-radius: 45px;
  }
  .user_profile_with_name {
    height: 8vh;
  }
  .user_profile_title_card {
    height: 18vh;
    padding: 4% 0 0 5%;
  }
  .user_profile_card_division {
    height: 30vh;
  }
  .single-fact-check-rating-text {
    font-size: 5vh;
  }
  .single-fact-check-related-fact-check-title {
    font-size: 5vh;
  }
  .related_fact_check_name_view {
    font-size: 3vh !important;
  }

  .fact_check_rectangle_name {
    font-size: 2vh !important;
  }
  .award_image {
    width: 40% !important;
    /* width: 320px !important; */
    height: 30vh;
    z-index: 1 !important;
    opacity: 0.5;
    border-radius: 8px;
    border: 2px solid white !important;
  }
  .award_card_content {
    margin-left: 100px !important;
    position: absolute;
    height: 60px;
    width: 40vw;
    background-color: var(--award-content);
    margin-left: 158px;
    margin-top: 165px;
    z-index: 2;
    display: flex;
  }
  .award_and_recognition {
    width: 100% !important;
  }
  .award_recognition_card {
    height: 40vh !important;
    width: 100%;
    margin-top: 50px;
    box-shadow: inset -80px 0 70px 0 #4d332f;
    background: linear-gradient(147.32deg, #140f2d 40.85%, #f49d36 169.52%);
    width: 100%;
    height: auto !important;
    padding: 20px;
  }
  .user_article_count {
    width: 15vw;
    gap: 10px;
  }
  .user_details_card {
    width: 80vw !important;
    height: auto;
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap: 30px;
  }
  .user_profile_book_mark_card {
    height: 20vh;
    width: 35vw;
    padding: 0 2% 0 2%;
  }
  .profile_card_title_with_icon {
    height: 5vh;
    align-items: center;
  }
  .user_article_or_fact_check_count {
    height: 5vh;
  }
  .icon_out_line {
    height: 20px !important;
    width: 20px !important;
  }
  .user_details_first_division {
    justify-content: space-between;
  }
  .about-us-header {
    /* width: 100%; */
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    gap: 25px;
    padding: 0 0 8% 0;
    height: auto !important;
  }
  .about-us-container {
    width: 87%;
  }
  .about-us-breadcrumbs {
    width: 100%;
    height: 100px;
    margin-left: 10px;
  }
  .mission-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .you-turn-text-content {
    width: 90%;
    margin-top: 15px;
  }
  .meet_the_team {
    width: 100%;
    padding: 0 0 0 5%;
    background: linear-gradient(115deg, #111a42 10.75%, #f49d36 348.16%);
  }
  .meet_the_team_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ifcn_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
  }
  .mobile_ifcn_text {
    display: none;
  }
  .fact_check_card {
    width: 100% !important;
    height: auto !important;
    padding: 20px;
  }
  .fact_check_details {
    width: 100%;
    margin-top: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
  }
  .fact_check_square {
    width: 92%;
    height: 45vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6% 0 0 7%;
  }
  .custom-square-card {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    flex-direction: column;
    height: auto !important;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
  }
  .fact_categories_name {
    display: flex;
    align-items: flex-start;
    margin-left: 7%;
    margin-top: 60px;
  }
  .donation_card_amount {
    height: 20px;
    width: 15vw;
  }
  .user_claim_donation {
    width: 90vw;
  }
  .funding_description_responsive {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 5% 0 0 0;
  }
  .statements_button {
    width: 225px;
    height: 45px;
    font-size: 15px;
    color: #007eff;
    border-color: #007eff;
    margin-bottom: 15px;
    border-radius: 10px;
    font-family: Lato;
  }
  .you-turn-image {
    width: 80%;
    height: 100%;
    margin-left: 29px;
  }
  .you-turn-text {
    width: 90%;
    display: flex;
    justify-content: center;
    margin-left: 29px;
  }
  .meet_team_heading {
    width: 91%;
    display: flex;
    justify-content: center;
  }
  .fight_against_fake_news_card {
    display: none;
  }
  .funding_paragraph_text {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .statements_full_button {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .funding_component {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    margin-top: 50%;
    padding: 8% 8% 0 8%;
  }
  .award_and_recognition_laptop {
    display: none;
  }
  .about_us_social_media {
    display: none;
  }
  .about_us_fact_categories_name {
    font-size: 4vh;
  }
  .article_page_thumbnail_image_with_title_subtitle {
    display: none;
  }
  .count_circle_out_lined {
    height: 20px;
    width: 12vw;
  }
  .videos-page-story-div {
    width: 90%;
    gap: 5vh !important;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .you-turn-videos-card {
    height: 70vh !important;
    border-radius: 10px;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
  }
  .article_list_carousal {
    padding: 6% 0 6% 7%;
  }
  .fact-check-and-article-categories-list {
    display: none;
  }
  .user_profile_bread_crumbs {
    padding-bottom: 2% !important;
  }
  .donation_details_no_data .ant-empty-image {
    align-items: center;
    height: 6vh;
  }
  .user_donation_details {
    width: 90vw;
  }
  .article_list_headline {
    margin-top: 10px !important;
    height: auto;
    cursor: pointer;
  }
  .related_article_button_color {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 8px;
  }
  .user_profile_donation_details {
    height: 5vh;
  }
  .user_profile_donation_card {
    height: 20vh;
    width: 35vw;
    padding: 0 2% 0 2%;
  }
  .user_profile_donation_date {
    width: 15vw;
  }
  .user_profile_claim_date_and_topic {
    width: 15vw;
  }
  .user_profile_card_div {
    width: 100%;
    padding: 3% 3% 3% 5%;
  }
  .user_profile_background_div {
    height: 99vh;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 5% 5%5% 5%;
  }
  .user_profile_details_first_div {
    height: 12vh;
    padding: 4% 0 0 6%;
  }
  .edit_user_profile_button {
    width: 8vh;
  }
  .editor_profile_card {
    height: 20vh;
    width: 35vw;
    padding: 0 2% 0 2%;
  }
  .author_profile_background_div {
    height: 99vh;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 5% 5%5% 5%;
  }
  .author_profile_count_card {
    height: 20vh;
    width: 35vw;
    padding: 0 2% 0 2%;
  }
  .social_media_card {
    height: 20vh;
    width: 35vw;
    padding: 0 2% 0 2%;
  }
  .header-stripe {
    display: none !important;
  }
  .article_page_bread_crumbs {
    display: none;
  }
  .article_first_card_mobile {
    z-index: 2;
    position: relative;
    padding: 0 3% 0 3%;
  }
  .article_mobile_background_container {
    background-color: #140f2d;
    height: 300px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .top_first_article_in_mobile {
    position: relative;
  }
  .no_data_for_category_wise_article {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
