@media only screen and (min-width: 320px) {
  .footer {
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
    background-color: #fafcff;
    padding: 20px;
  }
  .simple_article {
    /* width: 100% !important; */
    overflow-x: hidden;
    height: 100vh;
  }
  .fact-check-fist-half-row {
    /* margin-top:30px;
      margin-left: -14px; */
  }
}
