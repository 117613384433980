/* Laptop screens */
:root {
  --gradient-color: linear-gradient(147.32deg, #140f2d 40.85%, #f49d36 169.52%);
}
@media only screen and (min-device-width: 1024px) and (max-width: 4968px) {
  .fact_check_card_mobile {
    width: 110vh !important;
    height: 60vh !important;
    border-radius: 8px;
    z-index: 1;
  }

  .top-content-fact-check {
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
  }

  .top-content-videos {
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: center;
  }
  .single-fact-check-content-div {
    display: flex;
    justify-content: center;
    padding: 30px;
  }
  .article_list_news_story_card_lists {
    height: auto;
    width: 55%;
    gap: 40px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .article_list_news_story_card {
    width: 100%;
    height: 1200px;
    margin-top: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .article_load_more_button {
    margin-right: 20px;
    width: 75%;
    display: flex;
    justify-content: center;
  }
  .article_load_more {
    background-color: #007dff;
    border: transparent;
    color: white;
    border-radius: 4px;
    width: 300px;
  }
  .article_list_trending_now {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 38%;
  }
  .article_page_content_col {
    width: 50% !important;
    /* margin-top: 30px; */
    display: flex;
    flex-direction: column;
  }

  .single_article_related_fact_check {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 6.5vw;
    height: 78%;
  }

  .fact_check_category_card_image {
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 30vh;
  }
  .fact_check_category_card_image_div {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .fact_check_category_card_image_div:hover .fact_check_category_card_text{
    overflow: hidden !important;
    transform: translateY(80px);
    transition:  0.8s ease-in;
  }
  .fact_check_category_card_image_div .fact_check_category_card_text{
    overflow: hidden !important;
    transition:  0.8s ease-in;
  }
  .fact_check_category_card {
    position: relative;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.13);
    cursor: pointer;
    border-radius: 10px;
  }
  .fact_check_categories_skeleton {
    height: 300px;
    width: 500px;
    /* background-color: red; */
  }

  .donation-card-div {
    width: 30%;
    position: relative;
    margin-top: 6vh;

    /* bottom: 25px; */
    /* height: 40px; */
    /* margin-top: 27px; */
  }
  .custom-fact-check-rectangle-small-card {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: auto;
  }

  .party-wise-update-fist-div {
    width: 60%;
    margin-left: 35px;
    margin-left: 65px;
  }
  .party-wise-update-fist-row {
    height: 100vh;
    display: flex;
    margin-top: 6vh;
  }
  .right-outlined-col {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .trending-stories-carousel {
    width: 100%;
    margin-left: 6%;
    margin-top: 10px;
    max-height: 250px;
  }
  .trending-stories-first-row {
    background-color: #1e1745;
    height: 300px;
    margin-top: 40px;
    position: relative;
  }
  .trending-stories-first-col {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
    /* background-color: #05ae0e; */
  }

  .fact-check-second-div {
    width: 100%;
    height: 40vh;
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    gap: 25px;
  }

  .footer {
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
    background-color: #fafcff;
    padding: 20px;
  }
  .social_media_list_card {
    padding: 10% 0 20% 20% !important;
    background: linear-gradient(
      166.4deg,
      #140f2d 9.74%,
      #f49d36 281.54%
    ) !important;
    border-radius: 15px !important;
    height: 77vh !important;
    border: transparent !important;
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.3) !important;
  }
  .contact-card-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }
  .contact-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .divider-feedback-msg {
    border-color: #e79432 !important;
    height: 1px;
    margin-right: 5px;
  }
  .contact-text {
    color: white;
    font-size: 30px;
    /* font-weight: bold; */
    margin-bottom: 20px;
  }
  .award_recognition_card {
    height: 40vh !important;
    width: 100%;
    /* margin-top: 50px; */
    box-shadow: inset -80px 0 70px 0 #4d332f;
    background: linear-gradient(147.32deg, #140f2d 40.85%, #f49d36 169.52%);
    width: 100%;
    height: auto !important;
    padding: 20px;
  }

  .donation-amount-badges-other-btn {
    width: 60px;
    height: 20px;
    background-color: dodgerblue;
  }
  .donation-amount-badges {
    display: flex;
    width: 280px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 5px 13px 0px;
  }
  .contactus_socialmedia_card_box {
    margin-top: 40px;
  }
  .contact-page {
    padding: 2% 5% 5% 7%;
    justify-content: space-between;
  }

  .laptop-view-report-share {
    height: 40px;
    width: 47%;
    border-top: 1px solid #f49d36;
    border-bottom: 1px solid #f49d36;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    left: 45%;
    margin-top: 5%;
  }

  .single-fact-view-report-share {
    height: 40px;
    border-top: 1px solid #f49d36;
    border-bottom: 1px solid #f49d36;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* left: 43%; */
    /* margin-top: 5%; */
  }

  .save_icon {
    gap: 5px;
    align-items: center;
  }

  .laptop-single-fact-check-second-col {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
  }

  .details_explanation_content {
    width: 42%;
  }

  .single_fact_icon_card_image {
    width: 11vh !important;
    margin-left: 5px !important;
  }
  .see_all_article_button {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
  }

  .youturn_videos_box {
    width: 100%;
    height: 100vh;
    background: rgba(20, 15, 45, 1);
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
  }

  .see_all_stories_box {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    gap: 60px;
  }

  .custom_fact_check_views_and_icon {
    color: #e84756;
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 6px; */
    margin-bottom: 6px;
  }

  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }
  .carousel_card {
    width: 93%;
    margin-left: 3%;
  }
  .subtitle_text {
    height: 20vh;
    align-items: flex-start;
    display: flex;
    line-height: 3.2vh;
    overflow: hidden;
    margin-top: 7px;
    padding: 1% 0% 0% 0%;

    /* background-color: pink; */
  }

  .exposive_subtitle_text {
    height: 17vh;
    align-items: flex-start;
    display: flex;
    line-height: 2.8vh;
    overflow: hidden;
    margin-top: 7px;
  }

  .subtitle_text_user_contributed_stories {
    height: 24vh;
  }
  .article_browse_categories_title_card {
    width: 100%;
    padding: 0 0 0 7%;
  }

  .hover_link_color:hover {
    color: #0065cd !important;
    text-decoration: underline;
  }

  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }

  .laptop-page-end-row {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .laptop-page-end-line {
    width: 1100px;
    height: 1px;
    background-color: #0065cc;
  }
  .laptop-post-input {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid gray;
    outline: none;
    width: 61vw !important;
    font-size: 10px;
    font-weight: bold;
    color: #000000;
  }

  .subtitle_vertical_card {
    width: 248px !important;
    height: 16px !important;
    margin-left: 4px !important ;
  }
  .subtitle_skeleton {
    margin-top: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .skelton_name_date {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    margin-left: -7px;
  }

  .skelton_view_time {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    padding: 0 0 0 4%;
  }
  .skeleton {
    margin-top: 13px;
  }
  .skeleton_heading_card {
    margin-top: 20px !important;
    padding: 8px !important;
    width: 19.2vw !important;
  }

  .name_and_date_vertical_card {
    line-height: 18px;
    display: flex;
    padding: 2% 5% 0 5%;
    /* flex-direction: column; */
    /* margin-left: 15px; */
  }

  .social_media_list {
    display: none;
  }

  .fact_check_categories_list_selector {
    display: none;
  }
  .fact_check_categories_list {
    /* width: 95%; */
    margin-top: 20px;
    /* height: 300px; */
    /* flex-direction: column; */
  }

  .article_title {
    margin-top: 3vh;
  }

  .recommended_stories_text {
    display: flex;
    justify-content: column;
    height: 16vh;
    align-items: flex-start;
  }

  .article_carousel_card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .top_six_article {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    padding: 3% 0 0 7%;
    height: 26%;
  }

  .article_news_story_card_vertical {
    width: 35%;
  }

  .article_donation_card {
    width: 33%;
  }
  .article_card_first_half {
    display: none;
  }

  .fact_check_inside_card_width {
    width: 94% !important;
    margin: 4% 4% 0 4%;
    align-items: center;
    justify-content: start !important;
    gap: 20px;
    height: 12vh;
  }
  .article_donation_card_small {
    display: none;
  }
  .view_icon {
    color: #e84756;
    width: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .view_count_and_icon {
    display: flex;
    flex-direction: row;
    gap: 3px;
  }
  .fact_check_inside_card {
    width: 100%;
    display: flex;
    height: 5vh;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 4% 0 4%;
  }
  .join_us_card {
    height: 180px;
    width: 92%;
  }
  .join_us_card_title_and_button {
    margin-top: 30px;
    padding: 0 0 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .become_member_button {
    margin-top: 20px !important;
  }

  .join_us_button {
    width: 100% !important;
    border-radius: 9px;
    border: transparent;
  }

  .join_us_button_contribute {
    width: 90% !important;
    border-radius: 9px;
    border: transparent;
  }

  .article_top_title {
    height: 18vh;
    margin-top: 3vh;
  }
  .article_browse_categories {
    width: 100%;
    height: auto;
    gap: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    /* margin-left: -7vh; */
  }

  .media_share {
    width: 17%;
    margin-top: -5%;
  }
  .category_wise_article {
    width: 85%;
    padding: 2% 0 0 0;
  }
  .article_browse_categories_text {
    width: 100vh;
    height: 10%;
    display: flex;
    /* justify-content: center !important; */
  }

  .news_article_card_content-mobile {
    display: none !important;
  }
  .news_article_card_content-desktop {
    display: block !important;
  }

  .article_page {
    width: 100%;
    height: 50vh;
    background-color: #140f2d;
    display: flex;
    padding: 0 0 0 6%;
    /* justify-content: center; */
    gap: 25px;
  }

  .article_featured_story {
    color: #f49d36;
    font-weight: bold;
    font-size: 4vh;
  }

  .article_headline_text {
    color: #ffffff;
    font-size: 2.8vh;
    font-weight: bold;
    font-style: Lato;
  }

  .article_sub_title {
    font-size: 2vh;
    color: #140f2d;
    line-height: 4vh;
  }

  .new_you_turn_article {
    width: 41vw;
  }

  .trending_stories {
    gap: 20px !important;
  }
  .news_article_card_content-mobile {
    display: none !important;
  }

  .article {
    display: flex;
    justify-content: space-between;
    height: 70vh;
  }
  .fact_check_inside_card_mobile_view {
    width: 30vw !important;
    margin-top: 40vh;
    /* width: 70vh !important; */
    background-color: rgba(20, 15, 45, 0.9) !important;
    z-index: 2;
    position: absolute;
  }
  .custom-fact-check-card-first-half {
    width: 31vw !important;
    margin-top: 40vh;
    margin-left: -41px;
    /* width: 70vh !important; */
    background-color: rgba(20, 15, 45, 0.9) !important;
    z-index: 2;
    position: absolute;
  }

  .custom-half-card-inside-text {
    width: 36vw !important;
    background-color: rgba(20, 15, 45, 0.9);
    margin-top: 40vh;
    /* width: 70vh !important; */
    background-color: rgba(20, 15, 45, 0.9) !important;
    z-index: 2;
    position: absolute;
  }
  .user_contribution_content {
    padding: "2% 0 0 0";
    width: 100%;
    height: 50%;
    /* background-color: yellow; */
  }

  .news_article_card_content-desktop {
    display: block !important;
  }

  /* .report-card {
      width: 620px !important;
    } */
  .user_contribution_text {
    /* width: 40% !important; */
    width: 79vh;
    padding: 0 0 0 3% !important;
    /* margin-top: 5vh; */
  }
  .user_contribution_top_image {
    width: 100% !important;
  }
  .slogan {
    margin-top: 15px !important;
  }
  .about_us_award_and_recognition {
    padding: 6% 0 6% 0;
  }
  .fact_check_cared_bottom_text {
    line-height: 3vh !important ;
    width: 100%;
    font-weight: 800;
  }
  /* .fact_check_card_bottom_text{
    line-height: 3vh !important ;
    width: 100%;
  } */
  .categories_text {
    padding: 2% 0 3% 0;
  }

  .card-heading {
    height: 41vh !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .views-and-reading-time {
    width: 5.5vw !important;
    justify-content: flex-start;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .text-icon {
    gap: 8px;
    height: 20px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .article-meta-data-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 8px; */
    height: 5vh;
    width: 100%;
    /* margin-left: 1vh; */
  }
  .card-image-content {
    width: 55%;
    object-fit: contain;
    overflow: hidden;
  }
  .card-image-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .card-text-content {
    width: 45% !important;
  }
  .youturn-article-card {
    cursor: pointer;
    display: flex;
    height: 42vh;
    /* width: 42vw; */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
  }

  .youturn-articles-text {
    /* height: 11vh; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }
  .fake-news-text {
    width: 30vh;
    align-items: flex-start;
  }
  .donate-width {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(29% - (2 * 10px));
    justify-content: flex-end;
    padding: 10px;
  }
  .donation-horizontal-card-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .donation-horizontal-card {
    background: var(--gradient-color) !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    height: auto;
    width: 100%;
  }
  .center-align-views-and-reading-time {
    width: 100%;
    display: flex;
    gap: 0.6vh;
    flex-direction: row;
    color: #61666d;
    align-items: center;
  }

  .article_top_image {
    width: 110vh !important;
    height: 70vh !important;
    border-radius: 8px;
    z-index: 1;
    cursor: pointer;
  }

  .award-card-content-image {
    width: 30px;
    height: 30px;
    z-index: 1;
  }
  .award-card-content-image-box {
    height: 40px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .awards-and-recognition-text {
    height: 60px;
    width: 19vw;
    margin-top: 5px;
    line-height: 10px;
  }
  .carousel-desktop {
    /* margin-left: 10%; */
  }

  .article_list_news_story_card_lists {
    height: auto;
    width: 55%;
    gap: 40px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .article_list_news_story_card {
    width: 100%;
    height: 1200px;
    margin-top: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .article_load_more_button {
    margin-right: 20px;
    width: 75%;
    display: flex;
    justify-content: center;
  }
  .article_load_more {
    background-color: #007dff;
    border: transparent;
    color: white;
    border-radius: 4px;
    width: 300px;
  }
  .article_list_trending_now {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 38%;
  }

  .single_article_donation_and_social_media {
    width: 30%;
  }

  .custom-fact-check-rectangle-small-card {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: auto;
  }

  .right-outlined-col {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .trending-stories-carousel {
    width: 100%;
    margin-left: 6%;
    margin-top: 10px;
    max-height: 250px;
  }
  .trending-stories-first-row {
    background-color: #1e1745;
    height: 300px;
    margin-top: 40px;
    position: relative;
  }
  .trending-stories-first-col {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
    /* background-color: #05ae0e; */
  }

  .footer {
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
    background-color: #fafcff;
    padding: 20px;
  }

  .contact-card-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }

  .contact-text {
    color: white;
    font-size: 30px;
    /* font-weight: bold; */
    margin-bottom: 20px;
  }

  .see-all-btn-videos {
    background-color: #140f2d;
    color: #ffffff;
    border-radius: 10px;
    width: 110px;
    border: 1px solid white;
  }
  .youturn-videos-text-btn {
    display: flex;
    flex-direction: row;
    height: 70px;
    width: 90%;
    justify-content: space-between;
  }
  .donation-amount-badges-other-btn {
    width: 60px;
    height: 20px;
    background-color: dodgerblue;
  }
  .donation-amount-badges {
    display: flex;
    width: 280px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 5px 13px 0px;
  }
  .contactus_socialmedia_card_box {
    margin-top: 40px;
  }

  /* .laptop-comment-box {
      background-color: white !important;
    } */

  .laptop-single-fact-check-second-col {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
  }

  .details_explanation_content {
    width: 42%;
  }

  .single_fact_icon_card_image {
    width: 11vh !important;
    margin-left: 5px !important;
  }

  .article_list_news_story_card_lists {
    height: auto;
    width: 55%;
    gap: 40px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .article_list_news_story_card {
    width: 100%;
    height: 1200px;
    margin-top: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .article_load_more_button {
    margin-right: 20px;
    width: 75%;
    display: flex;
    justify-content: center;
  }
  .article_load_more {
    background-color: #007dff;
    border: transparent;
    color: white;
    border-radius: 4px;
    width: 300px;
  }
  .article_list_trending_now {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 38%;
  }

  .custom-fact-check-rectangle-small-card {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: auto;
  }

  .right-outlined-col {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .trending-stories-carousel {
    width: 100%;
    margin-left: 6%;
    margin-top: 10px;
    max-height: 250px;
  }
  .trending-stories-first-row {
    background-color: #1e1745;
    height: 300px;
    margin-top: 40px;
    position: relative;
  }
  .trending-stories-first-col {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
    /* background-color: #05ae0e; */
  }

  .footer {
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
    background-color: #fafcff;
    padding: 20px;
  }

  .contact-card-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }

  .contact-text {
    color: white;
    font-size: 30px;
    /* font-weight: bold; */
    margin-bottom: 20px;
  }

  .donation-amount-badges-other-btn {
    width: 60px;
    height: 20px;
    background-color: dodgerblue;
  }
  .donation-amount-badges {
    display: flex;
    width: 280px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 5px 13px 0px;
  }
  .contactus_socialmedia_card_box {
    margin-top: 40px;
  }

  .report_share_icon {
    gap: 5px;
    align-items: center;
  }

  .report_icon {
    gap: 5px;
    align-items: center;
  }

  /* .laptop-comment-box {
      background-color: white !important;
    } */

  .laptop-single-fact-check-second-col {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
  }

  .details_explanation_content {
    width: 42%;
  }

  .single_fact_icon_card_image {
    width: 11vh !important;
    margin-left: 5px !important;
  }

  .landing_page_vertical_image {
    height: 28vh !important;
    /* justify-content: center !important; */
    /* margin-bottom: 20px; */
  }

  .submit_claim_box {
    /* margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center; */
    display: none;
  }

  .custom_news_story_card_box_first_col {
    width: auto;
    height: auto;
    display: flex;
    justify-content: start;
    overflow: hidden !important;
  }
  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }

  .carousel_card {
    width: 93%;
    margin-left: 3%;
  }

  .hover_link_color:hover {
    color: #0065cd !important;
    text-decoration: underline;
  }

  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }
  .user_contributed_box {
    background-color: red;
    width: 100%;
    height: 600px;
    display: flex;
    gap: 1%;
    justify-content: center;
  }

  .fact-check-rectangle-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 69vh;
  }

  .laptop-fact-check-categories {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 4vw;
  }
  .laptop-page-end-row {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .laptop-page-end-line {
    width: 1100px;
    height: 1px;
    background-color: #0065cc;
  }

  .continue_reading_text {
    font-size: 1.7vh;
    font-family: "Lato";
    display: flex;
    flex-direction: column;
    padding: 0 5% 0 5% !important;
    color: gray;
    /* margin-top: 13px; */
    /* background-color: yellow; */
  }

  .skeleton {
    margin-top: 13px;
  }

  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }

  .carousel_card {
    width: 93%;
    margin-left: 3%;
  }

  .hover_link_color:hover {
    color: #0065cd !important;
    text-decoration: underline;
  }

  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }

  .laptop-page-end-row {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .laptop-page-end-line {
    width: 1100px;
    height: 1px;
    background-color: #0065cc;
  }

  .skeleton {
    margin-top: 13px;
  }

  .article_carousel_card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .news_article_card_content-mobile {
    display: none !important;
  }
  .news_article_card_content-desktop {
    display: block !important;
  }

  .landing_page_news_article {
    width: 20.5vw;
  }

  .trending_stories {
    gap: 20px !important;
  }

  .news_article_card_content-mobile {
    display: none !important;
  }

  .news_article_card_content-desktop {
    display: block !important;
  }

  .article_join_us_card {
    /* padding: 5% 0 0 0; */
  }

  .article_list_news_story_card_lists {
    height: auto;
    width: 55%;
    gap: 40px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .article_list_news_story_card {
    width: 100%;
    height: 1200px;
    margin-top: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .article_load_more_button {
    margin-right: 20px;
    width: 75%;
    display: flex;
    justify-content: center;
  }
  .article_load_more {
    background-color: #007dff;
    border: transparent;
    color: white;
    border-radius: 4px;
    width: 300px;
  }
  .article_list_trending_now {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 38%;
  }

  .related_fact_check_card_wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    padding: 6% 0 0 0;
  }

  .landing-page-user-contributed-stories {
    margin-right: 330px;
    padding: 30px;
  }

  .videos-recently-added {
    /* margin-right: 330px;
    padding: 30px; */
  }
  .fact_check_category_card_text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44%;
    background-color: rgba(20, 15, 45, 0.75);
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 3%;
  }
  .image-top-half-card-landing-page {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44%;
    background-color: rgba(20, 15, 45, 0.75);
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 3%;
  }
  .custom-half-card-inside-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44%;
    background-color: rgba(20, 15, 45, 0.75);
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 3%;
  }

  .custom-fact-check-rectangle-small-card {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: auto;
  }

  .right-outlined-col {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .trending-stories-carousel {
    width: 100%;
    margin-left: 6%;
    margin-top: 10px;
    max-height: 250px;
  }
  .trending-stories-first-row {
    background-color: #1e1745;
    height: 300px;
    margin-top: 40px;
    position: relative;
  }
  .trending-stories-first-col {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
    /* background-color: #05ae0e; */
  }

  .browse_category_mobile_view {
    width: 100%;
    height: auto;
    margin-top: 45vh;
    gap: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: -7vh;
  }

  .footer-first-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    margin-top: 25px;
  }
  .footer {
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
    background-color: #fafcff;
    padding: 20px;
  }

  .contact-card-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }

  .contact-text {
    color: white;
    font-size: 30px;
    /* font-weight: bold; */
    margin-bottom: 20px;
  }

  .donation-amount-badges-other-btn {
    width: 60px;
    height: 20px;
    background-color: dodgerblue;
  }
  .donation-amount-badges {
    display: flex;
    width: 280px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 5px 13px 0px;
  }
  .contactus_socialmedia_card_box {
    margin-top: 40px;
  }

  /* .laptop-comment-box {
      background-color: white !important;
    } */

  .laptop-single-fact-check-second-col {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
  }

  .details_explanation_content {
    width: 42%;
  }

  .single_fact_icon_card_image {
    width: 11vh !important;
    margin-left: 5px !important;
  }

  .custom_news_story_card_date {
    font-size: 10px;
    display: flex;
    flex-direction: column;
    color: #61666d;
  }

  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }

  .carousel_card {
    width: 93%;
    margin-left: 3%;
  }

  .hover_link_color:hover {
    color: #0065cd !important;
    text-decoration: underline;
  }

  .custom_news_story_card_box {
    cursor: pointer;
    height: 34vh;
    border-radius: 9px;
    flex-wrap: nowrap;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
  }
  .custom_news_story_card_box:hover
    .custom_news_story_card_box_first_col_image {
    transform: scale(1.1) !important;
    transition: transform 0.9s ease-in-out !important;
  }
  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }

  .laptop-page-end-row {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .laptop-page-end-line {
    width: 1100px;
    height: 1px;
    background-color: #0065cc;
  }

  .skeleton {
    margin-top: 13px;
  }

  .article_carousel_card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .news_article_card_content-mobile {
    display: none !important;
  }
  .news_article_card_content-desktop {
    display: block !important;
  }

  .trending_stories {
    gap: 20px !important;
  }

  .news_article_card_content-mobile {
    display: none !important;
  }

  .user_contribution_headline {
    /* margin-top: 5vh !important; */
    /* width: 85% !important; */
    /* background-color: red; */
    /* height: 16vh; */
    cursor: pointer !important;
    overflow: hidden;
  }

  .news_article_card_content-desktop {
    display: block !important;
  }

  .article_list_news_story_card_lists {
    height: auto;
    width: 55%;
    gap: 40px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .article_list_news_story_card {
    width: 100%;
    height: 1200px;
    margin-top: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .article_load_more_button {
    margin-right: 20px;
    width: 75%;
    display: flex;
    justify-content: center;
  }
  .article_load_more {
    background-color: #007dff;
    border: transparent;
    color: white;
    border-radius: 4px;
    width: 300px;
  }
  .article_list_trending_now {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 38%;
  }

  .custom-fact-check-rectangle-small-card {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: auto;
  }

  .right-outlined-col {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .trending-stories-carousel {
    width: 100%;
    margin-left: 6%;
    margin-top: 10px;
    max-height: 250px;
  }
  .trending-stories-first-row {
    background-color: #1e1745;
    height: 300px;
    margin-top: 40px;
    position: relative;
  }
  .trending-stories-first-col {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
    /* background-color: #05ae0e; */
  }

  .footer {
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
    background-color: #fafcff;
    padding: 20px;
  }

  .contact-card-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }

  .contact-text {
    color: white;
    font-size: 30px;
    /* font-weight: bold; */
    margin-bottom: 20px;
  }

  .donation-amount-badges-other-btn {
    width: 60px;
    height: 20px;
    background-color: dodgerblue;
  }
  .donation-amount-badges {
    display: flex;
    width: 280px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 5px 13px 0px;
  }
  .contactus_socialmedia_card_box {
    margin-top: 40px;
  }

  /* .laptop-comment-box {
      background-color: white !important;
    } */

  .laptop-single-fact-check-second-col {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
  }

  .details_explanation_content {
    width: 42%;
  }

  .single_fact_icon_card_image {
    width: 11vh !important;
    margin-left: 5px !important;
  }

  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }

  .carousel_card {
    width: 93%;
    margin-left: 3%;
  }

  .hover_link_color:hover {
    color: #0065cd !important;
    text-decoration: underline;
  }

  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }

  .laptop-page-end-row {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .laptop-page-end-line {
    width: 1100px;
    height: 1px;
    background-color: #0065cc;
  }

  .skeleton {
    margin-top: 13px;
  }

  .article_carousel_card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .article_article_small_card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 16vh;
    height: 89vh;
    /* width: 30vw; */
  }

  .news_article_card_content-mobile {
    display: none !important;
  }
  .news_article_card_content-desktop {
    display: block !important;
  }

  .trending_stories {
    gap: 20px !important;
  }

  .news_article_card_content-mobile {
    display: none !important;
  }

  .news_article_card_content-desktop {
    display: block !important;
  }

  .award-recognition-mobile {
    display: none !important;
  }

  .social_media_list_title {
    color: white;
    width: 100%;
    font-size: 3vh !important;
    font-weight: bold;
  }

  .article_list_news_story_card_lists {
    height: auto;
    width: 55%;
    gap: 40px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .article_list_news_story_card {
    width: 100%;
    height: 1200px;
    margin-top: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .article_load_more_button {
    margin-right: 20px;
    width: 75%;
    display: flex;
    justify-content: center;
  }
  .article_load_more {
    background-color: #007dff;
    border: transparent;
    color: white;
    border-radius: 4px;
    width: 300px;
  }
  .article_list_trending_now {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 38%;
  }

  .custom-fact-check-rectangle-small-card {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: auto;
  }
  .party-wise-update-title {
    display: flex;
    flex-direction: column;
    height: 100px;
    align-items: flex-start;
    justify-content: center;
  }

  .right-outlined-col {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .trending-stories-carousel {
    width: 100%;
    margin-left: 6%;
    margin-top: 10px;
    max-height: 250px;
  }
  .trending-stories-first-row {
    background-color: #1e1745;
    height: 300px;
    margin-top: 40px;
    position: relative;
  }
  .trending-stories-first-col {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
    /* background-color: #05ae0e; */
  }

  .footer {
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
    background-color: #fafcff;
    padding: 20px;
  }

  .contact-card-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }

  .contact-text {
    color: white;
    font-size: 30px;
    /* font-weight: bold; */
    margin-bottom: 20px;
  }

  .subscribe-youtube-videos {
    background-color: #0065cc;
    color: #ffffff;
    border-radius: 5px;
    width: 250px;
    border: none;
  }

  .cursor-pointer {
    cursor: pointer;
  }
  .donation-amount-badges-other-btn {
    width: 60px;
    height: 20px;
    background-color: dodgerblue;
  }
  .donation-amount-badges {
    display: flex;
    width: 280px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 5px 13px 0px;
  }
  .contactus_socialmedia_card_box {
    margin-top: 40px;
  }

  /* .laptop-comment-box {
      background-color: white !important;
    } */

  .laptop-single-fact-check-second-col {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
  }
  .fact-check-claim-detailed-explanation-row {
    width: 95%;
  }
  .details_explanation_content {
    width: 42%;
  }
  .laptop-text-comment {
    width: 3vw;
    border-radius: 60%;
    overflow: hidden;
    /* background-color: #0065cc; */
    height: 6vh;
    margin-left: 10px;
  }
  .sign-in-cancel-button {
    background-color: white !important;
    color: #241a30 !important;
    border-radius: 6px;
    border: 1px solid #241a30 !important;
  }
  .sign-in-button {
    background-color: #241a30 !important;
    color: white !important;
    border-radius: 6px;
  }
  /* 
  .sign-in-button:hover {
    background-color: white;
    color: #241a30;
  } */

  .single_fact_icon_card_image {
    width: 11vh !important;
    margin-left: 5px !important;
  }
  .custom_language_text_vertical {
    padding: 6% 5% 0 5%;
    line-height: 25px;
    height: 12.8vh;
    width: 20.5vw;
    /* background-color: gray; */
    overflow: hidden;
  }

  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }

  .carousel_card {
    width: 93%;
    margin-left: 3%;
  }

  .continue_reading_box {
    /* height: 11vh; */
    width: 100%;
    padding-top: 3%;
    /* background-color: orange; */
  }

  .hover_link_color:hover {
    color: #0065cd !important;
    text-decoration: underline;
  }

  .see_all_stories_button {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3.2vw;
  }

  .videos_youtube_icon {
    width: 32px !important;
    height: 32px !important;
    margin-left: 20px !important;
  }
  .english_font {
    font-size: 18px !important;
  }
  .tamil_font {
    font-size: 14px !important;
  }

  /* .laptop-continue-button {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  } */

  .laptop-page-end-row {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .laptop-page-end-line {
    width: 1100px;
    height: 1px;
    background-color: #0065cc;
  }

  .laptop-join-us-card-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 28vh;
  }
  .single-fact-check-comment-you-turn-image-row {
    display: flex;
    flex-direction: row;
    width: 86%;
  }

  .laptop-eye-image-row {
    color: #e84756;
    width: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .continue_reading_vertical_card {
    color: #0d99ff;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    /* padding: 5%; */
    font-size: 10px;
  }

  .skeleton {
    margin-top: 13px;
  }

  .youturn_articles_row_list {
    margin-left: -10px !important;
    margin: auto !important;
    padding: 40px 70px 0px 70px;
  }

  .single_article_logo_text {
    margin-top: 28px;
  }
  .single_article_logo_text .see_all_button {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
  }

  .donation_button_note {
    width: 100% !important;
  }
  .donate_button_width {
    width: 100% !important;
    margin-top: 1vh !important;
  }

  .article_recommended_stories_container {
    width: 100%;
    padding: 3% 6% 0 7%;
  }

  .recommended_stories_title_box {
    height: 89vh;
    width: 20.8;
  }

  .article_carousel_card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .article_recommended_stories {
    display: flex;
    justify-content: space-between;
  }

  .news_article_card_content-mobile {
    display: none !important;
  }
  .news_article_card_content-desktop {
    display: block !important;
  }

  .inner_article {
    /* width: 86%; */
    /* height: 100vh; */
  }

  .article_continue_reading {
    color: #0065cc;
    text-decoration: underline;
    font-size: 2vh;
    font-weight: bold;
    cursor: pointer;
  }
  .video_load_button_color {
    display: flex;
    justify-content: center;
    border-radius: 8px;
    width: 27vw !important;
  }

  .article_headline {
    margin-top: 2vh !important;
    height: 21vh;
    cursor: pointer;
    overflow: hidden;
  }

  .article_content {
    padding: 2% 0 0 0;
    height: 37.5vh;
    cursor: pointer;
    overflow: hidden;
  }

  .landing_page_news_articles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-top: 1px; */
    height: 67vh !important;
  }

  .trending_stories {
    gap: 20px !important;
  }

  .article_list {
    gap: 8px !important;
    margin-right: 1px !important;
    display: flex;
  }
  .news_article_card_content-mobile {
    display: none !important;
  }

  .news_article_card_content-desktop {
    display: block !important;
  }

  .you-turn-logo {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .mobile-screen {
    display: none !important;
  }
  .mobile_search_image {
    display: none !important;
  }
  /* .report-card {
      width: 620px !important;
    } */

  .user_contribution_top_image {
    width: 100% !important;
  }
  .slogan {
    margin-top: 15px !important;
  }
  .about_us_award_and_recognition {
    padding: 6% 0 6% 0;
  }
  .categories_text {
    padding: 2% 0 3% 0;
  }
  .fact_check_square_card {
    width: 100%;
    height: 400px;
    /* padding: 4% 0 4% 0; */
  }
  .fact_check_square_card_name {
    display: flex;
    align-items: flex-start;
    margin-left: 7%;
  }

  .fact_check_square {
    width: 92%;
    height: 45vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6% 0 0 7%;
  }

  .custom-square-card {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    width: 20%;
    height: 100%;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
    cursor: pointer;
  }
  /* .custom-square-card_image {
      display: flex;
      justify-content: center;
      text-align: center;
    } */

  /* .about_us_check_icon {
      width: 40%;
    } */
  .policy_button {
    width: 35%;
    height: 40%;
    font-size: 2.5vh;
    border-radius: 7px;
    color: #007eff !important;
    border-color: #007eff !important;
  }

  .policy_buttons {
    width: 100%;
    display: flex;
    gap: 100px;
    margin-top: 3%;
    margin-left: 7%;
  }
  .fact_check_card {
    width: 100%;
    height: 70%;
    padding: 4% 0 4% 0;
  }

  .custom-rectangle-card {
    display: flex;
    justify-content: space-between;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
    padding: 10px;
    width: 40%;
  }

  .quickaccess {
    margin-top: 45px !important;
  }

  .user_contribution_recently_added_component {
    margin-top: 250px;
  }
  .user_contribution_headline_text {
    color: #ffffff;
    font-size: 3vh;
    font-weight: bold;
    font-style: Lato;
  }

  .recently_added {
    height: 600px;
  }

  .big-screen {
    display: block !important;
  }
  .copyright-small-screen {
    display: none;
  }
  .meet_the_team {
    width: 100%;
    padding: 0 0 0 5%;
    background: linear-gradient(115deg, #111a42 10.75%, #f49d36 348.16%);
  }
  .join_us {
    width: 100%;
    height: 28vh;
    display: flex;
    flex-direction: row;
    background: linear-gradient(147.32deg, #140f2d 40.85%, #f49d36 169.52%);
  }

  .subscription-container {
    margin-top: 20px !important;
  }
  .custom_social_media {
    /* margin-top: 4vh !important;
    margin-bottom: 2vh; */
    display: flex;
    gap: 40px;
    width: 100%;
  }

  .donation-card {
    width: 100% !important;
    /* padding: 9%; */
  }

  .user-contribute-fact-div {
    width: 100% !important;
    background-color: #140f2d;
    height: 93.5vh;
    border-radius: 15px;
    padding: 4vw;
  }

  .social-media-list {
    width: 330px !important;
  }

  .single_article_page_second_col_antibiotic {
    width: 65% !important;
    height: 70vh;
  }
  .single_fact_check_page_second_col_antibiotic {
    width: 75vw !important;
  }
  .single-article-page-first_division {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: space-between;
  }
  .single_article_page_bread_crumbs {
    width: 100%;
  }

  .your-row-class {
    width: 100%;
    background-color: #140f2d;
    display: flex;
    height: 50vh;
    justify-content: center;
    padding: 2% 7% 2% 7%;
  }

  .single_article_image_skeleton {
    height: 55vh !important;
    width: 100% !important;
  }

  .skeleton_video_image_skeleton {
    height: 60vh !important;
    width: 100% !important;
  }

  .fact-check-row-class {
    height: 56vh;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #140f2d;
  }

  .videos-row-class {
    height: 50vh;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #140f2d;
  }

  .fact-check-top-box {
    width: 89%;
    height: 73vh;
    padding: 3vh;
  }

  .videos-top-box {
    width: 89%;
    padding: 3vh;
  }
  .fact-check-space-between {
    display: flex;
    justify-content: space-between;
  }

  .single_article_image {
    width: 100% !important;
    /* margin-top: 150px; */
  }

  .fact-check-bread-crumbs-col {
    width: 26%;
    height: 70vh;
  }
  .report-share-icon-div {
    height: 6.2vh;
    width: 20vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-left: 30px;
  }

  .you-turn-logo-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25vw;
    height: 25vh;
  }
  .you-turn-logo-row {
    width: 20vw;
    height: 13vh;
  }
  .fact-check-you-turn-logo-col {
    width: 7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .fact-check-date-views-col {
    width: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .eye-image-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .fact-check-clock-circle-image {
    width: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fact-check-bread-crumbs-div {
    height: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .single-fact-check-div {
    width: 20vw;
    height: 39vh;
    overflow: hidden;
  }

  .single-fact-check-title-small-div {
    height: 36.5vh;
    overflow: hidden;
  }

  .single-fact-check-title {
    color: #ffffff;
    line-height: 6vh !important;
    font-size: 3.5vh;
    font-weight: bold !important;
  }

  .single_article_page_first_col_antibiotic {
    width: 30% !important;
    height: 70vh;
  }
  .single_article_page_top_content {
    height: 85vh;
  }
  .single_article_title_row {
    padding: 2% 0 0 0;
    height: 36vh;
    overflow: hidden;
  }

  .icon_card_image {
    margin-top: 18px !important;
    width: 100px !important;
  }
  .report_share_card {
    display: none !important;
  }

  .single_article_continue_reading {
    background-color: #0065cc;
    border: transparent;
    color: white;
    width: 310px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
  }
  .article_page_content_image {
    width: 550px !important;
  }
  .antibiotic_content_row {
    width: 100% !important;
  }

  .donation_card_small_view {
    display: none !important;
  }

  .antibiotics_image {
    display: none !important;
  }

  .landing_page_titles {
    width: 25%;
    margin-left: 10px;
    height: 74vh;
  }
  .signatory_image {
    width: 10.6vw;
    cursor: pointer;
    /* background-color: red; */
  }
  .signatory_logo {
    height: 46.3vh;
    /* background-color: green; */
  }

  .exposing_fake_news_text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* gap: 20px; */
    height: 10vh;
  }
  .exposing_fake_news_image {
    width: 30vh !important;
    margin-right: 6px;
  }
  .rectangle_small_card_mobile_view {
    display: none !important;
  }
  .news_article_card {
    margin-left: 85px !important;
  }
  .hide_content_in_desktop {
    display: none !important;
  }
  .you_turn_video_card_mobile_screen {
    display: none !important;
  }
  .party_wise_title {
    margin-top: 10px;
  }
  .party_wise_content {
    width: 70%;
    margin-top: 30px;
  }

  .award_and_recognition {
    height: auto;
    min-width: 1300px;
    /* margin-left: 5%; */
  }
  .award_card {
    width: 10vw;
    /* height: 260px; */
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }
  .user_contributed_stories_mobile_view {
    display: none !important;
    background-color: red;
  }
  .single_fact_check_explanation {
    display: flex;
    justify-content: space-between;
    width: 90%;
  }

  .fact_check_explanation {
    margin-top: 30px;
  }
  .footer_check_box_with_text {
    display: flex;
  }
  .you_turn_video_card {
    height: 80px;
    width: 300px;
    position: absolute;
    margin-top: 180px !important;
  }
  .you_turn_video_page_card {
    height: 6vh !important;
    width: 30vw;
    position: absolute;
    display: "flex";
    justify-content: "space-between";
    z-index: 2;
    margin-top: 45vh;
  }
  .trending_now {
    margin-left: 15%;
  }
  .arrow_image {
    height: 25px !important;
  }
  .article_list_trending_now {
    margin-left: 2%;
  }
  .trending_now_title {
    display: flex;
    align-items: center;
  }
  .articles {
    padding: 5%;
  }
  .fact_check_donation_small_card_mobile_view {
    display: none;
  }

  .ant-ant-typography name > .bcci {
    line-height: "0px" !important;
  }
  .explanation_image {
    height: auto !important;
  }

  .articles {
    padding: 5%;
  }
  .single_article_page_small_fact_check_card {
    height: 96%;
    display: flex;
    gap: 6%;
  }

  /* } */

  .ant-ant-typography name > .bcci {
    line-height: "0px" !important;
  }
  .explanation_image {
    height: auto !important;
  }

  .single_article_fact_check_categories {
    width: 50%;
    /* height: 100%; */
    height: 78vh;
  }
  .single_article_recommended_stories {
    width: 30%;
    height: 78vh;
    /* position: sticky;
    top: 10px; */
  }
  .single_article_small_fact_check_card {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    height: 68vh;
    justify-content: space-between;
  }
  .single_article_social_media {
    width: 100%;
    position: sticky;
    top: 10px;
  }
  .single_article_donation_card {
    height: 100%;
    display: flex !important;
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
  .single_article_related_fact_check_card {
    height: 84vh;
    margin-top: 70px;
  }
  .single_article_page_small_fact_check_card {
    height: 90%;
    display: flex;
    gap: 11%;
  }

  .alignnone {
    width: 100%;
  }
  .report_card {
    padding: 7% 0 0 0;
  }
  /* .share_report_save {
    padding: 23% 0 0 0;
  } */

  .user_contribution_recently_added_component {
    margin-top: 250px;
  }

  .recently_added {
    height: 600px;
  }

  .big-screen {
    display: block !important;
  }

  .social-media-list {
    width: 330px !important;
  }

  .single_article_title {
    color: #ffffff;
    font-size: 3vh;
    line-height: 36px !important;
  }

  .article_page_content_row {
    width: 100% !important;
    display: flex;
    justify-content: center;
    gap: 90px;
  }
  .single_article_continue_reading {
    background-color: #0065cc;
    border: transparent;
    color: white;
    width: 310px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
  }
  .article_page_content_image {
    width: 550px !important;
  }
  .antibiotic_content_row {
    width: 100% !important;
  }

  .exposing_fake_news {
    width: 41vw;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 67vh !important;
  }

  .exposing_fake_news_image {
    width: 30vh !important;
    margin-right: 6px;
  }

  .news_article_card {
    margin-left: 85px !important;
  }

  .party_wise_title {
    margin-top: 10px;
  }
  .party_wise_content {
    width: 70%;
    margin-top: 30px;
  }

  .user_contributed_stories_mobile_view {
    display: none !important;
    background-color: red;
  }

  .laptop-report-donatio-row {
    width: 88%;
    gap: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    height: 51.4vh;
  }

  .fact_check_explanation {
    margin-top: 30px;
  }
  .footer_check_box_with_text {
    display: flex;
  }

  .trending_now {
    margin-left: 15%;
  }
  .arrow_image {
    height: 25px !important;
  }
  .article_list_trending_now {
    margin-left: 2%;
  }
  .trending_now_title {
    display: flex;
    align-items: center;
  }
  .articles {
    padding: 5%;
  }

  .ant-ant-typography name > .bcci {
    line-height: "0px" !important;
  }
  .explanation_image {
    height: auto !important;
  }

  .articles {
    padding: 5%;
  }
  .single_article_page_small_fact_check_card {
    height: 96%;
    display: flex;
    gap: 6%;
  }

  /* } */

  .ant-ant-typography name > .bcci {
    line-height: "0px" !important;
  }
  .explanation_image {
    height: auto !important;
  }
  .categories_selector {
    display: none;
  }

  .single_article_page_small_fact_check_card {
    height: 90%;
    display: flex;
    gap: 11%;
  }

  .alignnone {
    width: 100%;
  }

  .background-red {
    background-color: red;
  }

  .single_article_view_icon {
    margin-top: 6px !important;
    gap: 4px !important;
  }

  .user_contribution_recently_added_component {
    margin-top: 250px;
  }

  .recently_added {
    height: 600px;
  }

  .big-screen {
    display: block !important;
  }

  .social-media-list {
    width: 330px !important;
  }

  .social-media-container-mobile {
    display: none !important;
  }

  .single_fact_check_page_second_col_antibiotic {
    width: 75vw;
  }

  .single_article_report_share_icons {
    height: 6vh;
    gap: 20px;
    display: flex;
    justify-content: flex-end;
    margin-right: -29px;
  }

  .join_us_content {
    margin-top: 20px !important;
    line-height: 4vh !important;
  }

  .icon_card_text_bold {
    /* font-size: 2.5vh; */
    font-weight: bold !important;
    cursor: pointer;
  }
  .icon_card_text {
    font-size: 2.5vh;
  }

  .single_article_continue_reading {
    background-color: #0065cc;
    border: transparent;
    color: white;
    width: 310px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
  }
  .article_page_content_image {
    width: 550px !important;
  }
  .antibiotic_content_row {
    width: 100% !important;
  }

  .landing_page {
    background-color: #140f2d;
    z-index: 2;
    position: relative;
    height: 50.7vh;
    display: flex;
    justify-content: space-evenly;
    gap: 4%;
    padding: 2% 6%;
  }
  .landing_page_mobile_view_title {
    display: none;
  }

  .news_article_carousel_card {
    display: none !important;
  }
  .exposing_fake_news_card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* gap: 3%; */
    padding: 0 6% 0 7%;
    width: 100%;
    /* height: 73.4vh; */
    margin-top: 220px;
  }

  .exposing_fake_news_image {
    width: 100%;
    margin-right: 6px;
  }

  .news_article_card {
    margin-left: 85px !important;
  }

  .party_wise_title {
    margin-top: 10px;
  }
  .party_wise_content {
    width: 70%;
    margin-top: 30px;
  }
  .submit_your_claim_form {
    width: 550px !important;
    margin-left: 40px !important;
  }

  .user_contributed_stories_mobile_view {
    display: none !important;
    /* background-color: red; */
  }

  .fact-check-button {
    width: 100px;
    height: 29px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: "none";
    background-color: gray;
    border-radius: 4px;
    border-bottom: none;
    border-right: none;
    border-top: none;
    border-left: none;
  }

  .fact_check_explanation {
    margin-top: 30px;
  }
  .footer_check_box_with_text {
    display: flex;
  }

  .trending_now {
    margin-left: 15%;
  }
  .arrow_image {
    height: 25px !important;
  }
  .article_list_trending_now {
    margin-left: 2%;
  }
  .trending_now_title {
    display: flex;
    align-items: center;
  }
  .articles {
    padding: 5%;
  }

  .ant-ant-typography name > .bcci {
    line-height: "0px" !important;
  }
  .explanation_image {
    height: auto !important;
  }
  .fact_check_categories_sub_title {
    padding: 3%;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    height: 60px;
    font-size: 11px;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.13);
  }
  .single_article_join_us_card {
    display: flex;
    justify-content: center;
  }

  .articles {
    padding: 5%;
  }
  .single_article_page_small_fact_check_card {
    height: 96%;
    display: flex;
    gap: 6%;
  }

  /* } */

  .ant-ant-typography name > .bcci {
    line-height: "0px" !important;
  }
  .explanation_image {
    height: auto !important;
  }

  .single_article_page_small_fact_check_card {
    height: 90%;
    display: flex;
    gap: 11%;
  }
  .article_top_image_skeleton {
    width: 110vh !important;
    height: 70vh !important;
  }
  .article_recent_sub_title {
    height: 43vh !important;
    width: 32vw !important;
    margin-top: 5vh !important;
  }
  .article_recent_title_skeleton {
    height: 7vh !important;
    width: 32vw !important;
    margin-top: 3vh !important;
  }
  .aligncenter {
    width: 100%;
    object-fit: contain !important;
    height: auto !important;
  }
  .alignnone {
    width: 100%;
  }

  .icon_card {
    padding: 4% 0 0 0;
  }

  .related-fact-check-row {
    width: 88vw;
    height: 95vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .related-fact-check-title {
    height: 17vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .fact-check-image-div {
    background-color: #f49d36;
  }

  .feature-story-sub-title-content {
    line-height: 4vh;
    margin-top: 2vh;
    cursor: pointer !important;
    overflow: hidden;
    height: 36vh;
    /* background-color: antiquewhite; */
  }
  .six-categories-card-first-div {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .laptop-single-fact-check-col {
    margin: auto !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    margin-left: 3vh !important;
  }
  .article_carousel {
    background-color: #1e1745;
    height: 300px;
    margin-top: 40px;
  }
  .top_six_article_card {
    width: 100%;
  }
.editor_article_list{
  display:flex;
  justify-content:center;
  margin-top: 15px;
}
  .fact_check_party_wise_selector {
    display: none !important;
  }
  .six-categories-card-second-div {
    width: 100%;
    margin-left: 10%;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    height: 50%;
    flex-direction: row;
  }
  .fact_check_top_image {
    /* width: 88vh !important;
    height: 58vh !important; */
    border-radius: 8px;
    z-index: 1;
    margin-left: -7vh;
  }
  .browse_categories_text {
    width: 40% !important;
    justify-content: flex-start;
    width: 40%;
    height: 10%;
    display: flex;
    margin-left: 10%;
  }

  .article_small_card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    /* margin-left: 6%; */
    padding-top: 16vh;
    height: 89vh;
    width: 30vw;
  }
  .single_article_page_article_date {
    margin-top: 5px;
  }
  .videos_img {
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-items: center;
  }
  .videos_container {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .videos_list_news_story_card {
    width: 93%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .you_turn_videos_story_card {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search_results_items {
    display: flex;
    justify-content: space-between;
    width: 80vw;
  }
  .you_turn_videos_list {
    display: flex;
    justify-content: space-between;
    width: 88%;
    margin-top: 20px;
  }

  .search_result_cards {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 40px;
  }
  .you_turn_videos_card {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 20px;
  }

  .load_more_button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .videos_page_load_more_button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15vh !important;
  }

  .load_button_color {
    width: 45%;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    background-color: #00acee;
    color: white;
  }
  .single-fact-check-view-time-col {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: "center" !important;
    /* width: 4vw; */
    margin-left: 3px;
  }
  .single-fact-check-view-col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: "center" !important;
  }
  .single-fact-check-top-image-col {
    width: 72%;
    height: 50vh;
  }

  .award_image {
    width: 31.2vw;
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.905);
    opacity: 0.8;
    overflow: hidden;
    object-fit: cover;
  }
  .award_card_content {
    display: flex;
    margin-top: -101px;
    background-color: #fffffff8;
    width: calc(43rem / 2);
    margin-left: 53px;
    height: 67px;
  }
  .single_article_view_report_share {
    width: 100%;
  }
  .list_of_article_top_image {
    width: 110vh !important;
    height: 70vh !important;
    border-radius: 8px;
    z-index: 1;
  }
  .list_of_article_inside_card {
    width: 30vw !important;
    background-color: rgba(20, 15, 45, 0.9);
    margin-top: 40vh;
    /* width: 70vh !important; */
    background-color: rgba(20, 15, 45, 0.9) !important;
    z-index: 2;
    position: absolute;
    cursor: pointer;
  }
  .list_of_article_inside_card_width {
    width: 94% !important;
    margin: 4% 4% 0 4%;
    align-items: center;
    justify-content: start !important;
    gap: 20px;
    height: 12vh;
  }
  .list_of_article_inside_card_title {
    font-size: 2vh !important;
    line-height: 3vh !important;
  }
  .list_of_article_view_count_and_icon {
    width: 100%;
    display: flex;
    height: 5vh;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 4% 0 4%;
  }
  .list_of_article_view_count_and_icon_div {
    display: flex;
    flex-direction: row;
    gap: 3px;
  }
  .fact_check_inside_card_title {
    font-size: 2vh !important;
    line-height: 3vh !important;
    overflow: hidden;
  }

  .article_page_inside_card {
    width: 30vw !important;
    margin-top: 40vh;
    /* width: 70vh !important; */
    background-color: rgba(20, 15, 45, 0.9) !important;
    z-index: 2;
    position: absolute;
    cursor: pointer;
  }
  .top_article_inside_card_width {
    width: 94% !important;
    margin: 4% 4% 0 4%;
    align-items: center;
    justify-content: start !important;
    gap: 20px;
    height: 12vh;
  }
  .article_inside_card_title {
    font-size: 2vh !important;
    line-height: 3vh !important;
  }
  .article_inside_cards {
    width: 100%;
    display: flex;
    height: 5vh;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 4% 0 4%;
  }
  .article_page_inside_card_title {
    font-size: 2vh !important;
    line-height: 3vh !important;
  }

  .article_inside_card {
    width: 100%;
    display: flex;
    height: 5vh;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 4% 0 4%;
  }
  .fact-check-news-vertical-by-fist-half {
    display: none;
  }
  .divider_see_all_button_small_card {
    display: none;
  }
  .about-us-header {
    /* width: 100%; */
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    gap: 25px;
    padding: 0 0 8% 0;
    height: auto !important;
  }

  .about-us-breadcrumbs {
    width: 100%;
    height: 100px;
    margin-left: 9px;
    margin-top: 2%;
  }

  .about-us-container {
    width: 87%;
  }
  .mission-text {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    width: 100%;
    padding: 30px;
  }

  .you-turn-text-content {
    width: 70%;
    margin-top: 15px;
  }

  .meet_the_team_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .single-fact-check-claim-column {
    width: 30%;
    position: sticky !important;
    top: 20vh;
    bottom: 10px;
    height: 63vh;
  }
  .single-fact-check-claim-column-second {
    width: 74%;
    line-height: 4vh;
  }
  .single-fact-check-content-title {
    height: 7vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .single-fact-check-related-fact-check-title {
    font-size: 4vh !important;
  }
  .single-fact-check-comment-row {
    /* width: 93%; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: auto;
  }
  .single-fact-check-comment-div {
    /* width: 57%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .single-fact-check-comment-full-div {
    display: flex;
    justify-content: center;
    /* padding: 60px; */
  }
  .single-fact-check-fake-image-div {
    height: 21vh;
    width: 18.5vw;
    display: flex;
    justify-content: center;
  }
  .single-fact-check-content-title-div {
    height: 7vh;
    width: 18vw !important;
    display: flex;
    justify-content: center;
  }
  .single-fact-check-claim-div {
    height: 8vh;
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
    /* background-color: pink; */
  }
  .single-fact-check-related-fact-check-col {
    width: 57%;
    display: flex;
    flex-direction: column;
  }
  .single-fact-check-related-fact-ful-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 12vh;
  }
  .donation-horizontal {
    background-color: var(--gradient-color);
  }
  .single_article_page_text {
    width: 80vh;
    padding: 0 0 0 3% !important;
  }
  .copyright-text-in-footer {
    display: none !important;
  }
  .article_list_in_list_of_article {
    display: none;
  }
  .videos_list_in_you_turn {
    display: none;
  }

  .fact-check-fourth-div {
    display: none !important;
  }
  .single-fact-check-you-turn-logo {
    width: 5vw !important;
    height: 5vw !important;
    border-radius: 50%;
    object-fit: cover;
  }
  .single-fact-check-report-card {
    /* height: 51.5vh; */
    width: 50vw;
    background-color: white;
    border-radius: 20px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .single-fact-check-report-share-div {
    display: none !important;
  }

  .single_fact_check_comment_title {
    font-size: 4vh;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 8vh;
  }

  .single_fact_check_comment_name {
    width: 100%;
    height: 4vh;
    font-size: 2.5vh;
  }

  .single_fact_check_comment_name_date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 4vh;
    font-size: 2vh;
  }

  .single-fact-check-user-details-div {
    display: flex;
    gap: 10px;
    height: 8vh;
  }
  .single-fact-check-comment-content-row {
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .single-fact-check-comment-content {
    width: 82%;
    height: auto;
    display: flex;
    flex-direction: row;
  }
  .single-fact-check-comment-reply-div {
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 16%;
  }
  .single-fact-check-comment-reply_icon {
    height: 4.5vh;
    cursor: pointer;
  }

  .trending-now-arrow-image {
    width: 25px !important;
  }
  .trending-now-arrow-with-text {
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
  }
  .article_list_news_stories_card {
    display: flex !important;
    flex-direction: column;
    gap: 40px;
    height: auto;
  }
  .you_turn_videos_list_stories_card {
    display: flex !important;
    flex-direction: column;
    gap: 40px;
    height: auto;
  }
  .list_of_article_page_news_article {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .single-fact-check-option-icon-div {
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .reply-comment {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid gray;
    outline: none;
  }
  .see_all_button {
    width: 67%;
    margin-left: 10%;
  }
  .landing-page-container {
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    background-color: #fafcfd !important;
  }

  .scroll-snap-child {
    flex: 0 0 auto; /* Prevent flex items from shrinking */
    scroll-snap-align: start; /* Snap to the start of each item */
    min-width: 100vw;
  }
  .article_featured_story_skeleton {
    height: 7vh !important;
    width: 32vw !important;
  }
  /* .fact-check-fist-half-row{
    margin-left: 15vh;
  } */
  .fact-check-third-div_dublicate {
    margin: auto !important;
    width: 90%;
  }
  .fact-check-third-div {
    margin: auto !important;
  }
  .fact-check-top-image-skeleton {
    width: 100vh !important;
    height: 60vh !important;
    /* margin-right: 10vh; */
  }
  .fact-check-top-title-and-subtitle-skeleton {
    width: 100vh !important;
    height: 30vh !important;
  }
  .fact-check-and-article-categories-list {
    width: 90;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    /* height: 20vh; */
    /* background-color: red; */
  }
  .youtube-views-text {
    margin-top: 4px;
  }
  .you-tube-views-text {
    margin-top: 6px;
  }
  .youtube-page-views-text {
    font-size: 2.5vh;
  }
  .content-dark {
    background-color: #1f1f1f !important;
    color: white !important;
  }
  .dark-mode-text {
    color: white !important;
  }
  .article_page_thumnail_image_with_text {
    height: 110vh;
  }
  .article_list_news_story_card_sub_title {
    line-height: 2.5vh !important;
  }
  .article_list_news_story_card_title {
    line-height: 3vh !important;
  }
  .social_media_logo {
    height: 62vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10% 0 0 0;
    cursor: pointer;
  }

  .article_list_page_thumnail_image_with_text {
    height: 112vh;
  }
  .you_turn_videos {
    height: 45vh;
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fact-check-list-three-trending-skeleton {
    width: 40vh !important;
    height: 30vh !important;
  }
  .fact-check-list-news-story-card-skeleton {
    width: 50vw !important;
    height: 40vh !important;
    display: flex;
  }
  .custom-fact-check-categories-six-skeleton {
    height: 100vh;
    width: 20vw;
  }
  .single-fact-check-mick-image {
    /* height: 123px; */
    width: 10.6vw !important;
  }
  .part-wise-stories-secondary-container {
    margin-left: 25px !important;
  }

  .social_media_icons_first_row {
    display: flex;
    justify-content: center;
    width: 90%;
  }

  .header-drawer-social-media-second-div {
    display: flex;
    justify-content: center;
    width: 78%;
  }
  .social-media-list-fact-check {
    position: sticky !important;
    top: 10px !important;
  }
  .six-categories-card-second-div-for-skeleton {
    width: 23vw !important;
    /* margin-left: 10%; */
    display: flex;
    /* flex-wrap: wrap; */
    gap: 35px;
    height: 170px !important ;
    flex-direction: row;
  }
  .fact_check_category_card:hover.fact_check_category_card_image {
    transform: scale(1.2) !important;
    transition: transform 0.7s ease-in-out !important;
  }
  .small_video_card_paly_button {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 260px;
    height: 160px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .exposing_fake_news_title {
    margin-top: 10px;
  }
  .single-fact-check-comment-reply {
    cursor: pointer;
  }

  .single-fact-check-comment-list {
    border: 1px solid #6f6f6f;
    width: 100%;
    border-radius: 10px;
    padding: 2vh;
    background-color: #ffffff;
    overflow: auto;
  }

  .single-fact-check-comment-profile-image {
    width: 25%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user_contribution_top_half {
    display: flex;
    justify-content: space-between;
    height: 70vh;
    margin-top: 3vh;
  }
  .landing_page_user_contributor_text_with_card {
    width: 20.5vw;
    height: 93vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .single-fact-check-comment-mobile {
    font-size: 2vh;
  }

  .single-fact-check-reply-comment-text {
    font-size: 2vh;
  }
  .single-fact-check-reply-image-text {
    width: 2vw !important;
  }

  .single-fact-check-reply-comment-text-comment {
    width: 3vw !important;
    border-radius: 60%;
    overflow: hidden;
    /* background-color: #0065cc; */
    height: 6vh;
  }
  .user-contribution-list-news-story-card-skeleton {
    width: 40vw !important;
    height: 30vh !important;
    margin-top: 3vh;
  }
  .single-fact-check-child-reply-comments-text {
    font-size: 2.1vh;
  }
  .mobile-view-profile-image {
    width: 3vw;
  }

  .single-fact-check-mobile-view-profile-image {
    width: 3vw;
  }
  .top_contibutors_card_skeleton {
    width: 50% !important;
    height: 25vh !important;
    margin-bottom: 5vh;
    /* margin-left: 5vh; */
    /* background-color: yellow ; */
    /* display: flex;
    flex-direction: row; */
  }

  .vertical_card_container:hover .landing_page_vertical_image {
    transform: scale(1.2) !important;
    transition: transform 0.8s ease-in-out !important;
  }
  .vertical_card_container .landing_page_vertical_image {
    transition: transform 0.8s ease-in-out;
  }

  .ant-form-item-label > label.white-text-label {
    color: white !important;
  }
  .ant-col .ant-form-item-label > label.white-text-label {
    color: white !important;
  }

  .landing_page_vertical_image_card {
    overflow: hidden !important;
    height: 24vh;
  }
  .article_page_thumbnail_image_with_title_subtitle {
    height: 97vh;
    /* height: auto !important; */
  }
  .article_page_stay_connected {
    display: none;
  }
  .media_name {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .views-full-box {
    height: 15vh;
    width: 16vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .youtube-youtube-videos-text {
    line-height: 35px !important;
  }
  .ant-input-affix-wrapper {
    /* background-color: white !important;
    color: #000000 !important; */
    background-color: transparent !important;
    color: white !important;
  }
  /* .ant-input-affix-wrapper > input.ant-input {
    background-color: transparent !important;
    color: white !important;
  } */
  .carousel-container {
    display: flex;
    justify-content: space-evenly;
  }
  .user_contribution_text_skeleton {
    height: 10vh !important;
    width: 30vw !important;
  }
  .user_contribution_title_skeleton {
    height: 10vh !important;
    margin-top: 10px !important;
    width: 30vw !important;
  }
  .user_contribution_subtitle_skeleton {
    height: 30vh !important;
    width: 30vw !important;
    margin-top: 3vh !important;
  }
  .fact_check_vertical_card_skeleton {
    width: 20vw !important;
    height: 70vh !important;
  }
  .party_wise_small_card_skeleton {
    width: 30vw !important;
    height: 15vh !important;
    margin-left: 3vh !important;
  }
  .single-fact-check-sign-up-row {
    width: 50vw;
    /* background-color: pink; */
  }
  .single-fact-check-sign-up-col {
    width: 50%;
  }
  .single-fact-check-sign-up-model {
    display: flex;
    flex-direction: "row";
    align-items: center;
    justify-content: center;
    width: 80vw;
  }

  .single-fact-check-no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 28vh;
  }

  .single-fact-check-rating-text {
    font-size: 2.6vh;
  }
  .mobile_search_bar {
    display: none;
  }

  .search_result_bread_crumbs {
    padding-bottom: 3%;
    width: 80%;
  }
  .search_bread_crumbs_with_topic {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .search_result_header_content {
    width: 100%;
    height: auto;
    padding: 0 0 0 7%;
  }
  .search_input_and_button {
    width: 93%;
    margin-top: 40px;
  }
  .desk_menu_select_drop_down {
    width: 160;
  }
  .selector_and_divider {
    height: 17vh;
    padding: 1.5% 0 0 7%;
  }
  .desk_menu_select_drop_down {
    width: 15vw;
  }
  .button_divider {
    width: 50%;
  }
  .search_result_rectangle_small_card {
    display: none;
  }
  .search_result_trending_now {
    width: 25%;
    gap: 10vh;
  }
  .search_result_social_media_list {
    display: none;
  }
  .search_result_social_media_sticky {
    position: sticky;
    top: 10px;
  }
  .search_result_items_division {
    padding: 0% 0 0 7%;
  }
  .search_result_recently_added_mobile_carousel {
    display: none;
  }
  .search_result_news_article {
    display: flex;
    gap: 5vh;
    flex-direction: column;
  }
  .see_all_button_categories {
    width: 280px !important;
  }
  .party_tag_vertical_and_small_card_div {
    margin-top: 5vh;
  }
  .fact_check_category_tags {
    width: 90;
    display: flex;
    flex-wrap: wrap;
    gap: 2vh;
    cursor: pointer;
    /* height:  15vh; */
  }
  .videos-top-image-contents {
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin-top: 5vh;
  }

  .videos-top-image {
    height: 70vh;
    width: 60%;
    position: relative;
  }
  .videos-top-title-div {
    height: 70vh;
    width: 35%;
  }
  .videos-top-featured {
    height: 35vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Skeleton-videos-top-featured {
    height: 35vh;
    width: 100% !important;
  }
  .videos-top-title {
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .videos-top-description {
    height: 28vh;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .videos-top-description-col {
    height: 26vh;
    overflow: hidden;
  }
  .videos-continue-text {
    overflow: hidden;
    width: 92.8%;
    height: 7vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .video-image {
    height: 70vh !important;
    width: 52.4vw !important;
    border-radius: "10px";
  }
  .video-title-text {
    font-size: 3.5vh;
    line-height: 5vh;
    color: white;
  }

  .videos-card {
    border-radius: 6px;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
    width: 700px;
    height: 40vh;
  }
  .videos_page_card {
    border-radius: 6px;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
    width: 48vw;
    height: 40vh;
  }

  .custom_small_video_card {
    height: 28vh;
    /* width: 55vw; */
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center !important;
  }
  /* .video_card_image {
    width: 400px;
    border: 1.5px solid rgb(231, 231, 231);
    border-radius: 10px 0 0 10px;
    height: 30vh !important;
  } */
  .videos_small_video_card {
    border-radius: 10px 0 0 10px;
  }

  .videos_page_small_video_card {
    border-radius: 10px 0 0 10px;
  }
  .videos_page_card_image {
    /* width: 23vw !important; */
    /* height: 40vh !important; */
    border-radius: 10px 10px 0 0;
    object-fit: contain;
  }
  /* .video_card_image {
    width: 35vw !important;
    height: 40vh !important;
    border-radius: 10px 0 0 10px;
  } */
  .video_card_content {
    width: 42% !important;
    gap: 6px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .video_page_card_content {
    width: 22.2vw;
    height: 40vh;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .video_page_mobile_card_content {
    display: none;
  }

  .video-page-title-text {
    font-size: 3vh;
    line-height: 5vh !important;
    color: white;
  }
  .video-page-subtitle-text {
    font-size: 2.5vh;
    line-height: 4vh;
    line-height: 5vh !important;
  }

  .skeleton-recent-fact-check {
    width: 20vw !important;
    height: 15vh !important;
  }
  .forgot_password_text {
    color: #000000;
  }
  .bread_crumbs_and_top_content_div {
    width: 90% !important;
    padding: 30px !important;
    /* margin-right: 3vh; */
    margin-top: -1vh;
  }
  .user_contribution_recently_added_mobile_view {
    display: none !important;
  }
  .user_contribution_recently_added_first_row {
    height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
    margin-top: 30px !important;
    margin-left: 3vh;
  }
  .top_contributors_mobile_view {
    display: none;
  }
  .user_contribution_donation_small_card_mobile_view {
    display: none;
  }
  .user_contribution_recommended_stories_mobile_view {
    display: none;
  }
  .user_contribution_all_stories_mobile_view {
    display: none;
  }
  .user_contribution_social_media_list_mobile_view {
    display: none;
  }

  .mobile_ifcn_text {
    display: none;
  }
  .ifcn_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* margin-top: 40px; */
    padding: 50px;
  }

  .fact_check_details {
    width: 100%;
    /* margin-top: 20px; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
  }

  .fact_check_container {
    display: flex;
    align-items: flex-start;
    padding: 0 0 4% 7%;
  }
  .donate_button_fact_check {
    width: 100%;
    /* height: 8vh; */
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;
    padding: 10px 5px 0px 0px;
  }

  .donation-input-div {
    width: 26vw;
    height: 9vh;
  }
  .mobile-donation-form {
    display: none !important;
  }

  .video-round-icon {
    height: 10vh;
    width: 6vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .you-video-page-title-text {
    font-size: 2.5vh;
  }
  .youtube-views-and-time {
    font-size: 1.8vh !important;
    /* font-weight: bold !important; */
  }
  .sign-up-logo-image {
    width: 2vw !important;
  }
  .sign-up-title-div {
    height: 10vh;
    width: 9.2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .user_profile_card {
    height: 20vh !important;
    width: 60vw !important;
    background: linear-gradient(147.32deg, #140f2d 40.85%, #f49d36 169.52%);
    padding: 3%;
  }
  .edit_icon_span {
  }
  .edit_icon_division {
  }
  .user_profile {
    height: 10vh;
    width: 5vw;

    border-radius: 50px;
  }
  .user_profile_with_name {
    height: 10vh;
  }
  .user_profile_title_card {
    height: 11vh;
    padding: 2% 0 0 7%;
  }
  .user_profile_card_division {
    height: 35vh;
  }
  .user_profile_book_mark_card {
    height: 40vh;
    width: 24vw;
    /* padding: 0 1% 0 1%; */
  }
  .profile_card_title_with_icon {
    height: 7vh;
    align-items: center;
    padding: 0 4% 0 4%;
  }
  .user_article_or_fact_check_count {
    height: 7vh;
  }
  .login_first_div {
    width: 100% !important;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .login_second_div {
    background: linear-gradient(to right, #c33764, #1d2671);
    color: #fff;
    margin: auto;
    display: flex;
    justify-content: center;
    height: 50vh;
    width: 45vw;
    align-items: center;
    border-radius: 1vh;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  }

  .login_title_div {
    position: absolute !important;
    top: 30vh !important;
    /* margin-top: 3vh; */
  }
  .login_form_row {
    width: 500px;
  }
  .login_form_first_div {
    width: 100% !important;
  }
  .login_button_div {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /* height: 10vh; */
    /* background-color: red; */
    /* align-items: center; */
    position: relative;
    top: 3vh;
  }
  .edit-profile-bread-crumbs-div {
    background-color: #140f2d;
    width: 100%;
    height: 14vh;
    display: flex;
    justify-content: center;
  }

  .edit-profile-bread-crumbs-title-div {
    width: 86%;
    height: 14vh;
  }

  .edit-profile-bread-crumbs-row {
    width: 100%;
    height: 8vh;
  }
  .edit-profile-profile-title {
    font-size: 5vh;
    font-weight: bold;
    color: white;
  }

  .edit-profile-update-delete-full-div {
    width: 86%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-profile-update-delete-div {
    width: 65%;
    height: 18vh;
    border-radius: 10px;
    background: linear-gradient(
      147.32deg,
      #140f2d 40.85%,
      #f49d36 169.52%
    ) !important;
    display: flex;
    flex-direction: row;
  }

  .edit-profile-pic-div {
    width: 10vw;
    height: 18vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
  }

  .edit-profile-update-pic-div {
    /* width: 20vw; */
    height: 18vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .edit-profile-delete-pic-div {
    width: 20vw;
    height: 18vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .edit-profile-image {
    width: 10vw !important;
  }
  .user_article_count {
    width: 10vw;
  }
  .icon_out_line {
    height: 20px !important;
    width: 20px !important;
  }
  .count_circle_out_lined {
    width: 10vw;
    /* align-items: center; */
    /* display: flex; */
    /* justify-content: center; */
    padding-bottom: 11%;
  }
  .user_details_card {
    width: 60vw !important;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* flex-direction: column; */
    gap: 30px;
  }
  .user_details_first_division {
    justify-content: space-between;
  }
  .edit-profile-text {
    color: white;
    margin-left: 1vw;
    cursor: pointer;
  }

  .change-password-text {
    color: white;
    margin-left: 1vw;
    cursor: pointer;
    font-weight: bold;
  }

  .edit-profile-update-form-input-div {
    width: 86%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-form-input {
  }

  .edit-profile-input-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  .you_turn_logo_image_div {
    position: absolute;
    top: 13.5vh;
    left: 39vw;
    margin-left: 4vh;
    margin-top: 14vh !important;
  }
  .bread-crumb-div {
    margin-left: -6vh !important;
    height: 13vh;
    margin-top: 2vh;
  }

  .login_button {
    background-color: #241a30 !important;
    color: white !important;
    border-radius: 6px;
    /* width: 20vw; */
  }
  .fact_categories_name {
    display: flex;
    align-items: flex-start;
    margin-left: 7%;
    /* margin-top: 60px; */
  }
  .funding_component {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    /* padding: 8% 0 0 0; */
  }
  .videos-page-story-div {
    width: 87%;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px;
  }
  .forgot_password_first_div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .forgot_password_second_div {
    background: linear-gradient(to right, #c33764, #1d2671);
    color: #fff;
    margin: auto;
    display: flex;
    justify-content: center;
    height: 50vh;
    width: 45vw;
    align-items: center;
    border-radius: 1vh;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  }
  .forgot_password_input {
    width: 70vh !important;
  }
  .forgot_password_title_div {
    position: absolute;
    top: 30vh;
    margin-left: 25vh;
    color: #fff !important;
  }
  .donation_card_amount {
    height: 20px;
    width: 15vw;
  }
  .user_claim_donation {
    width: 90%;
  }
  .fact_check_donation_card {
    background-color: #140f2d;
    border-radius: 15px;
    width: 32vw;
  }

  .search_result_recently_added_carousel {
    display: none !important;
  }

  .forgot_password_submit_button {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  .forgot_submit_button {
    /* background-color: #241a30 !important; */
    color: white !important;
    border-radius: 6px;
    border-color: #fff;
    background: linear-gradient(to right, #c33764, #1d2671) !important;
    color: #fff;
    font-weight: bold;
  }
  .login_turn_logo_image_div {
    position: absolute;
    top: 13.5vh;
    left: 39vw;
    margin-left: 9vh;
    margin-top: 14vh !important;
  }
  .reset_password_div {
    position: absolute;
    top: 30vh;
    margin-left: 3vh;
  }
  .reset_turn_logo_image_div {
    position: absolute;
    top: 13.5vh;
    left: 39vw;

    margin-top: 14vh !important;
  }
  .reset_password_submit_button {
    color: white !important;
    border-radius: 6px;
    border-color: #fff;
    background: linear-gradient(to right, #c33764, #1d2671) !important;
    color: #fff;
    font-weight: bold;
  }
  .reset_password_button_div {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  .reset_password_form_row {
    width: 90vh !important ;
  }
  .funding_description_responsive {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 5% 0 0 0;
  }
  .statements_button {
    width: 70%;
    height: 45px;
    font-size: 15px;
    color: #007eff;
    border-color: #007eff;
    margin-bottom: 15px;
    border-radius: 10px;
    font-family: Lato;
  }
  .you-turn-logo-container {
    width: 70%;
    margin-left: 55px;
  }
  .you-turn-image {
    width: 80%;
    height: 100%;
    margin-left: 29px;
  }
  .you-turn-text {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .meet_team_heading {
    width: 91%;
    display: flex;
    justify-content: center;
  }
  .donation_card_small {
    display: none;
  }
  .funding_paragraph_text {
    width: 35%;
    height: 520px;
    display: flex;
    flex-direction: column;
  }
  .statements_full_button {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .award_and_recognition_mobile {
    display: none;
  }
  .about_us_social_media {
    display: none;
  }
  .about_us_fact_categories_name {
    font-size: 4vh;
  }
  .user_contribution_recently_added_first_div {
    width: 59%;
  }
  .user_contribution_top_image_skeleton {
    width: 50vw !important;
    height: 70vh !important;
    border-radius: 10px;
  }
  .categories_see_all_button_divider {
    border-color: black !important;
    margin-right: 5px !important;
  }
  .see_all_button_mobile_view {
    display: none;
  }
  .donation-content-small-card-text {
    color: white;
    font-size: 1.8vh !important;
  }

  .donation-content-small-card {
    color: white;
  }

  .user-contribute-text {
    color: white;
    font-size: 2.6vh !important;
    line-height: 4vh !important;
  }
  .donation-input-amt-card {
    background-color: whitesmoke;
    width: 4vw;
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .donation-input {
    width: 100% !important;
  }
  .donation-card-input {
    width: 26.5vw !important;
    height: 7vh !important;
  }

  .submit_your_claim {
    height: 50vh !important;
    display: flex;
    flex-direction: row;
  }

  .video-check-bread-crumbs-div {
    height: 5vh;
  }
  .mobile_drawer_line {
    background-color: white;
    width: 80% !important;
    margin-left: 40px !important;
    border-bottom: 2px solid white;
  }

  .you-turn-videos-card {
    width: 27vw;
    height: 60vh;
    border-radius: 10px;
    gap: 25px;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
    cursor: pointer;
  }

  .you-turn-videos-card-image-first-div {
    height: 45.7vh;
    position: relative;
  }

  .youtube-logo-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .youtube-logo {
    width: 3.5vw !important;
  }
  .you-turn-videos-card-image-second-div {
    height: 13vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .you-turn-videos-card-title-div {
    height: 8vh;
    padding: 2px;
    width: 26vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .you-turn-videos-card-views-div {
    height: 4.9vh;
    padding: 2px;
    width: 26vw;
  }
  .mobile-pop-up {
    display: none !important;
  }
  .user_profile_list_div {
    width: 100%;
    height: auto !important;
    display: flex;
    justify-content: center;
    padding-top: 5%;
  }
  .user_profile_list_align {
    width: 90%;
    display: flex;
    justify-content: space-between;
    gap: 5vh;
    /* height: auto !important; */
    flex-wrap: wrap;
  }
  .user_profile_load_more_button {
    display: flex;
    justify-content: center;
    height: 15vh;
    align-items: center;
  }

  .user_profile_load_button_color {
    width: 20vw;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    color: white;
    background-color: #0065cd;
  }
  .article_list_carousal {
    padding: 6% 0 6% 7%;
  }
  .article_list_mobile_carousal {
    display: none;
  }
  .user_profile_fact_check_text:hover {
    text-decoration: underline;
  }
  .you_turn_logo_div {
    height: 28vh !important;
  }
  .back_arrow {
    width: 90%;
  }
  .sing_up_button_div {
    /* height: 10vh; */
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .forgot_submit_button:hover {
    background: linear-gradient(to right, #c33764, #1d2671);
    color: #fff;
  }
  /* .forgot_submit_button{
    background: linear-gradient(to right, #C33764, #1D2671) !important;
    color: #fff;
  }   */
  .sing_in_text {
    color: black;
  }
  .not_register_text {
    color: black;
  }
  .youturn_logo_mobile_view_only {
    display: none;
  }

  .landing_submit_button {
    width: 30vw !important;
  }
  .video-page-title-text-div {
    height: 20vh;
  }
  .user_donation_details {
    width: 80vw;
  }
  .initial_mobile_pop_up {
    display: none !important;
  }
  .profile-image-size {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20%;
  }

  .edit_user_back_button {
    background-color: white !important;
    color: black !important;
    width: 10vw !important;
    border-radius: 10px;
    height: 6vh !important;
    border: 1px solid black !important;
  }
  .edit_user_save_button {
    width: 10vw !important;
    border-radius: 10px;
    height: 6vh !important;
  }

  .update-user-profile-buttons-full-div {
    display: flex;
    justify-content: center;
    width: 23.5vw;
  }

  .update-user-profile-buttons-div {
    display: flex;
    justify-content: space-between;
    gap: 6px;
  }

  .mobile-sign-in-button {
    width: 80px;
    color: white;
    height: 25px;
    background-color: rgba(20, 15, 45, 1);
    border: 1px solid white;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
  }

  .user-and-sign-in-section {
    height: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .drawer_profile_icon_div {
    width: 3vw;
  }

  .drawer_profile_name_div {
    width: 14.5vw;
  }
  .drawer_profile_button_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 90%;
    height: 10vh;
  }

  .drawer_guest_div {
    width: 24.5vw;
    height: 5vh;
    display: flex;
    flex-direction: row;
  }
  .single-fact-check-top-image {
    width: 60.5vw !important;
    /* height: 76vh !important; */
    object-fit: cover !important;
  }

  .chain-profile-div {
    display: flex;
  }
  .article_list_headline {
    height: 29vh;
    cursor: pointer;
  }
  .related_article_button {
    width: 100%;
    height: 15vh;
  }
  .related_article_button_color {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    background-color: #0065cd;
    color: white;
  }

  .full-video-page-div {
    overflow-x: hidden;
  }
  .awards-div {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  /* .youtube_logo_div {
    width: 10% !important;
    margin-left: 9% !important;
    cursor: pointer;
  } */
  .user_profile_donation_details {
    height: 7vh;
    padding: 0 4% 0 4%;
  }
  .forgot_password_send_mail {
    margin: 1vh 4vh 1vh 7vh;
    color: #000000 !important;
  }
  .privacy_policy_main_div {
    height: auto;
    margin: 1vh 14vh;
    overflow-x: hidden;
  }
  .user_name_in_donation {
    padding-top: 3%;
  }
  .user_article_and_fact_check_div {
    display: flex;
    justify-content: space-between;
  }
  .user_article_and_fact_check {
    display: flex;
    flex-direction: column;
    width: 20vw;
    align-items: center;
  }
  .user_profile_bread_crumbs {
    padding-bottom: 1%;
  }
  .team_members_details {
    background-color: transparent;
    border-color: #ffb037;
    border-radius: 10px;
    margin-bottom: 10vh;
  }
  .user_profile_background_div {
    height: 92vh;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 4% 5% 4% 5%;
  }
  .user_profile_card_div {
    width: 100%;
    /* padding: 0 5% 0 7%; */
  }
  .user_profile_details_card_division {
    width: 26vw;
    height: 75vh;
    border-radius: 10px;
  }

  .user_profile_details_first_div {
    height: 12vh;
    padding: 4% 0 0 6%;
  }
  .user_profile_donation_card {
    height: 30vh;
    width: 24vw;
  }
  .donation_and_contribution_cards {
    height: 75vh;
  }
  .user_profile_donation_date {
    width: 10vw;
  }
  .user_profile_claim_date_and_topic {
    width: 10vw;
  }
  .user_profile_image {
    height: 19vh;
  }
  .user_profile_name_and_role {
    height: 5vh !important;
    /* padding-top: 5%; */
  }
  .claims_status {
    height: 20px;
    width: 8vw;
  }
  .user_profile_no_data_image {
    height: 25vh;
  }
  /* .user_image_in_donation > .ant-modal-content {
    width: 100% !important;
    padding: 0% !important;
  } */
  /* .user_image_in_donation .ant-modal-content {
    padding: 0 !important;
  } */

  /* .user_image_in_donation
    :where(.css-dev-only-do-not-override-zl9ks2).ant-modal
    .ant-modal-content {
    width: 60vw;
    padding: 20px -3px;
  } */
  .party-wise-update-fact-check-small-card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .fact_check_first_half_second_div {
    display: flex;
    margin-top: 1vh;
  }
  .edit_user_profile_button {
    width: 8vh;
  }
  .custom-fact-check-card-first-half_for_landing_page {
    width: 31vw !important;
    margin-top: 28vh;
    /* margin-left: -41px;  */
    /* width: 70vh !important; */
    background-color: rgba(20, 15, 45, 0.9) !important;
    z-index: 2;
    position: absolute;
  }
  .user_article_form {
    font-size: 23px;
    font-weight: 600;
    margin: 10px;
    color: #ffffff !important;
    margin-top: 20px;
  }
  .videos_skeleton_div {
    /* background-color: red; */
    width: 80%;
    display: flex;
    /* justify-content: center; */
    gap: 9vh;
    margin-left: 13vh;
  }
  .custom_new_article_card_inside_div {
    background-color: rgba(251, 249, 249, 0.75);
    width: 22vw;
    padding: 2%;
    height: 14vh;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
  }
  .single-fact-check-view_report_share {
    display: flex;
    flex-direction: column;
    height: 10vh;
    margin-top: 2vh;
  }
  .upload_image_button {
    background-color: transparent !important;
    padding: 4px 50px !important;
    color: white !important;
  }
  .member_description {
    width: 80%;
  }
  .team_member_image {
    display: flex;
  }
  .team_member_name {
    width: 75%;
  }
  .your-custom-classname {
    background-color: transparent !important;
    /* color: white !important; */
    color: gray !important;
    font-weight: 500;
  }
  .fact_check_headline {
    margin-top: 2vh !important;
    /* height: 21vh; */
    cursor: pointer;
  }

  .landing-author-text {
    font-size: 1.6vh;
  }
  .sign_in_button_div {
    /* height: 0vh;
    margin-Top: 10.5vh */
  }
  .sign_up_and_log_div {
    /* margin-top: "11.6vh !important"; */
  }
  .login_button_and_logo_image {
    margin-top: 12vh !important;
  }

  .video_card {
    position: relative;
    height: 300px;
    width: 513px;
    cursor: pointer;
  }

  .trending_stories_text {
    height: 8vh;
    overflow: hidden;
  }

  .invite_button {
    width: 25% !important;
    border-radius: 9px;
    border: transparent;
  }

  .single-fact-check-rating-image-size {
    width: 8vw !important;
  }

  .trending_stories_main_card {
    cursor: pointer;
    border-radius: 9px;
    height: 30vh;
    width: 22vw;
    box-shadow: rgba(0, 101, 204, 0.1) 2.4px 2.2px 5px 1.4px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .hide_about_page_in_web {
    display: none !important;
  }
  .square-card-image {
    width: 50%;
  }

  .you_turn_video_card_image {
    width: 513px !important;
    height: 300px !important;
    z-index: 1;
    border-radius: 10px;
    object-fit: cover;
  }

  .single-fact-check-whatsapp-div {
    height: 10vh;
    width: 100%;
  }

  .single-fact-check-whatsapp-button-div {
    /* background-color:#140f2d; */
    background-color: #00704a;

    height: 9vh;
    width: 75%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
  }

  .single-fact-check-whatsapp-image-size {
    width: 4vw !important;
    cursor: pointer;
  }

  .single-fact-check-whatsapp-text {
    font-size: 1.7vh !important;
    color: white;
    text-align: left;
    font-weight: 300;
    cursor: pointer;
    line-height: 2.2vh !important;
  }

  .single-fact-check-whatsapp-second-button-div {
    /* width: 13vw; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hide-desk {
    display: none;
  }

  .award_and_recognition_title {
    width: 100%;
    /* margin-left: 15px;
    margin-top: 40px; */
    margin: 50px 0 50px 0;
    /* padding: 0px 10px; */
  }

  .join_us_button_contribute_div {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landing-slot-card-title-text {
    line-height: 3.2 !important;
  }
  .icon_card_first_col {
    margin-top: 30px;
  }
  .fact-check-category-text {
    width: auto;
    flex: 1;
    font-size: 1.9vh;
    word-wrap: break-word;
  }
  .categories-content {
    width: 30vw;
    height: 12vh;
    max-height: 90px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    left: 48px;
    top: 38px;
    position: absolute;
    border-radius: 50px;
    border: 1px #140f2d solid;
    justify-content: flex-end;
    align-items: center;
    gap: 26px;
    display: flex;
  }
  .category-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20vh;
  }

  .landing-youturn-article-card-title-div {
    height: 9vh;
    width: 95%;
    overflow: hidden;
    padding: 1.4vh 0px 0px 0px !important;
  }

  .landing-youturn-article-card-subtitle-div {
    height: 17vh !important;
    width: 95%;
    overflow: hidden;
  }

  .landing-youturn-article-card-date-div {
    /* height: 6vh; */
    width: 95%;
  }

  .landing-youturn-article-card-continue-div {
    height: 4vh;
    width: 95%;
    display: flex;
    align-items: center;
  }

  .landing-youturn-article-card-title-text {
    font-size: 2vh;
    font-weight: 600;
    line-height: 2.6vh !important;
  }

  .landing-youturn-article-card-subtitle-text {
    font-size: 1.8vh;
    font-weight: 400 !important;
    line-height: 2.4vh !important;
  }

  .followus-card {
    width: 2.5vw;
  }
  .social-media-container-big {
    width: 40vw;
  }
  .social-media-mobile-screen {
    display: none;
  }
  .signatory_logo_in_footer {
    display: none;
  }
  .quick-access {
    /* height: 30vh; */
    width: 20vw;
  }

  .fact_check_rectangle_content {
    height: 13.4vh;
    width: 19vw;
  }
  .rectangle_small_card_first_half {
    height: 7vh;
    overflow: hidden;
  }
  .rectangle_small_card_second_half {
    height: 4vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }

  .custom_rectangle_small_card_first_half {
    height: 4vh;
    display: flex;
    align-items: center;
  }
  .custom_rectangle_small_card_second_half {
    height: 7vh;
    overflow: hidden;
  }
  /* .media-logo {
    height: 22px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  } */
  .youturn_transparent_logo {
    height: 90%;
    background-color: transparent !important;
    opacity: 0.5;
  }
  .submit_claim_file_upload_col {
    padding-left: 7px !important;
  }

  .single-article-whatsapp-link-div {
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .landing-youturn-video-card-div {
    display: flex;
    flex-direction: column;
    height: 73vh;
    justify-content: space-between;
  }

  .donation-card-small {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    height: 78vh;
  }

  .related-fact-check-title-div {
    /* height: 10vh; */
    overflow: hidden;
  }

  .related_fact_check_card_last_text {
    font-size: 10px;
    /* line-height: 3vh; */
  }
  .donation_card_horizontal_third_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .about_us_horizontal_first_div {
    background: var(--gradient-color) !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    /* height: 30vh; */
    width: 100%;
    border-radius: 20px;
  }
  .donation_text_for_about_us {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width: calc(29% - (2 * 10px)); */
    justify-content: flex-end;
    padding: 10px;
  }
  .donation_card_horizontal_contribution_main_div {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .contribution_main_div {
    /* width: 30%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .donation_card_horizontal_donate_button {
    width: 10%;
    align-items: center;
    display: flex;
    margin-top: -11px;
  }
  .donate_button_div {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    /* margin-top: -11px; */
    height: 15vh;
  }
  .fake_news_title {
    display: flex;
    width: 24%;
    gap: 5px;
  }
  .fake_news_title_for_about_us {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .landing-videos-page-col {
    width: 45%;
    height: 90vh;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* background-color: pink; */
  }

  .landing-page-videos-first-col {
    width: 40%;
    height: 85vh;
  }

  .landing-page-videos-first-col-div {
    display: flex;
    flex-direction: row;
    height: 10vh;
    justify-content: space-between;
  }
  .submit_your_claim_title_and_content {
    width: 22vw;
  }

  .header-logo {
    width: 1.3vw !important;
    cursor: pointer;
  }
  .language-options {
    display: none !important;
  }
  /* .twitter-tweet{
    width: 100vh !important;
    height: 200vh !important;
    flex-grow: 1 !important; 
    position: static !important;
    display: block !important;
  } */
  /* .mobile_drawer_title{
    display: none !important;
  }
  .mobile_drawer_moon{
    display: none !important;
  }
  .close-division{
    background-color: red;
    width: 100%;
  } */

  .become_member {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .footer-social-media {
    display: flex;
    gap: 15px;
  }
  .title_and_first_card_of_article {
    display: none;
  }
  .fact_news_title_main_div {
    padding: "20px 20px 0 20px";
  }
  .contact_title {
    padding: 2% 0 2% 0;
  }
  .contact_us {
    padding: 0 0 0 7%;
  }
  .feedback-form {
    width: 50vw;
  }
  .contact_us_donation_card {
    width: 35%;
  }
  .other_option_text {
    color: #1e1745;
    font-size: 2vh;
  }
  .feedback-input {
    color: black;
  }
  .contact_us_text_area {
    color: black;
  }
  .social-media-icons {
    /* width: 21%; */
    display: flex;
    justify-content: flex-end;
  }
  .social-media-logo-container {
    display: flex;
    justify-content: space-between;
    width: 16vw;
  }
  .segment-container {
    display: flex;
    align-items: center;
    height: 5vh;
  }
  .segmented-option {
    height: 3.5vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* color: white !important; */
  }

  .main-menu {
    color: white !important;
    width: 18vw;
    height: 4vh !important;
    background-color: transparent;
    display: flex;
    justify-content: end;
  }
  .edit_profile_input_field {
    border: none;
    box-shadow: none;
    width: 21vw;
  }

  .exposing_fake_news_skeleton_container {
    width: 100%;
    height: 53vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .exposing_fake_news_skeleton {
    width: 40vw !important;
  }
  .exposing_fake_news_skeleton_first {
    width: 40vw !important;
    height: 25vh !important;
  }

  .top_details_in_single_article {
    display: none !important;
  }
  .support_us_title {
    height: 15vh;
    padding: 0 5% 0 7%;
  }
  .support_us_first_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 3% 5% 0 7%;
  }
  .support_us_text {
    line-height: 30px !important;
    padding-top: 1%;
  }

  .support_us_horizontal_first_div {
    background: var(--gradient-color) !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    /* height: 65vh; */
    width: 100%;
    border-radius: 20px;
  }
  .contribution_card_in_support_us {
    width: 33% !important;
  }
  .support_us_container {
  }

  .single-fact-check-mobile-top-second-div {
    display: none !important;
  }

  .page-not-found-full-div {
    background-color: green;
    height: 100%;
  }

  .page-not-found-text {
    color: white;
    font-size: 6vh;
  }

  .page-not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85vh;
    /* background-image: url(''); */
    background-size: cover;
    background-position: center;
  }

  .page-not-found-title {
    font-size: 4rem;
    font-weight: bold;
    color: #ff6347;
  }

  .page-not-found-content {
    margin-top: 20px;
    text-align: center;
  }

  .page-not-found-message {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
  }

  .page-not-found-button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .page-not-found-button:hover {
    background-color: #0056b3;
  }
  .claim-news {
    font-size: 2.5vh;
  }
  .fact-check-content {
    font-size: 18px !important;
    font-weight: 500 !important;
    text-align: justify;
    background-color: #ffffff !important;
  }
  .article-content {
    font-size: 2.5vh;
    line-height: 3.8vh;
    text-align: justify;
  }

  .skeleton-fact-check-rating {
    height: 17vh !important;
    width: 10vw !important;
    margin-top: 1vh !important;
  }
  .landing-donation-width {
    width: 26vw !important;
  }
  .fact_check_rectangle_card:hover .rectangle_small_card_image {
    transform: scale(1.1) !important;
    transition: transform 0.8s ease-in-out !important;
  }
  .fact_check_rectangle_card .rectangle_small_card_image {
    transition: transform 0.8s ease-in-out !important;
  }
  /* .rectangle_small_card_image .small_card_image:hover {
    transform: scale(1.1) !important;
    transition: transform 0.8s ease-in-out !important;
  } */

  .terms_and_conditions {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 2vh;
    /* background-color: red; */
  }
  .contact_us_div_second_div {
    /* background-color: yellow; */
    height: 13vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .terms_and_conditions_text {
    /* background-color: yellow; */
    height: 5vh;
    display: flex;
    align-items: center;
  }

  .landing-article-card-author-text {
    font-size: 1.7vh !important;
  }
  .editor_image {
    width: 10vw !important;
    height: 10vw !important;
    border-radius: 50%;
    object-fit: cover;
  }
  .css-1ms7hib-MuiTimelineItem-root {
    display: flex;
    position: relative;
    min-height: 70px;
    width: 57vw !important;
  }
  .feature-story-sub-title-content-user-contribution {
    line-height: 4vh;
    margin-top: 2vh;
    cursor: pointer !important;
    overflow: hidden;
    height: 40vh;
  }
  /* .user_contribution_second_div{
    height: 90vh;
  } */
  .user_contribution_donation_card_div {
    width: 30%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    height: 95%;
  }
  .user_contribution_recently_added_second_div {
    display: flex;
    flex-direction: row;
    height: 12vh;
    align-items: flex-start;
  }
  .editor_profile_card {
    height: 30vh;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .editor_profile_inner_card {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 5% 0 5%;
  }
  .author_profile_background_div {
    height: 90vh;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 4% 5% 4% 5%;
    flex-direction: column;
  }
  .count_details_card {
    display: flex;
    /* justify-content: center; */
    /* gap: 10px; */
    /* justify-content: space-between; */
  }
  .author_profile_count {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
  }
  .author_profile_count_card {
    height: 30vh;
    width: 24vw;

    flex-direction: column;
  }
  .author_social_media_share {
    display: flex;
    gap: 7px;
  }
  .author_social_media_image {
    width: 5vh;
    padding-top: 6% !important;
    cursor: pointer;
  }
  .user_contribution_headline {
    height: 16vh;
    cursor: pointer !important;
    overflow: hidden;
  }
  .content_headline {
    cursor: pointer !important;
    overflow: hidden;
    height: 18vh;
  }

  .main-menu:hover {
    color: #f49d36 !important;
    background-color: transparent !important;
  }
  .horizontal {
    background-color: transparent !important;
  }
  .popup > .ant-menu {
    background-color: white !important;
  }

  .language_main_div {
    display: flex;
    width: 10vw;
    justify-content: space-evenly;
  }
  .social_media_card {
    height: 30vh;
    width: 24vw;
    flex-direction: column;
  }
  .author_face_book_image {
    width: 6vh;
    cursor: pointer;
  }
  .custom-tabs .ant-tabs-tab {
    font-size: 3vh !important;
  }

  .custom-active-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 3.5vh;
  }
  .search_result_no_data {
    width: 55vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
  }
  .search_result_join_us {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  .article_preview_container {
    background-color: #140f2d;
    height: 53vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  .preview_report_icon {
    width: 20px !important;
  }
  .preview_report_share_icon {
    height: 6vh !important;
    gap: 20px !important;
    display: flex;
    justify-content: flex-end;
  }
  .article_preview_second_container {
    position: relative;
    z-index: 2;
    display: flex;
    height: 85vh;
    width: 100%;
    justify-content: space-between;
    padding: 5% 7% 2% 7%;
  }
  .single_article_view_skeleton_loading {
    width: 25vw !important;
    height: 26vw !important;
  }
  .article_preview_page_content_row {
    width: 100% !important;
    display: flex;
    justify-content: center;
    gap: 90px;
    padding-bottom: 3%;
  }
  .back_button {
    width: 10vw;
  }
  .mobile_header_drawer {
    background: linear-gradient(166.4deg, #140f2d 9.74%, #f49d36 281.54%);
  }
  .landing_page_mobile_top {
    display: none !important;
  }

  .user_contribution_bread_cr_div {
    background-color: #140f2d;
    height: 5vh;
    display: flex;
    justify-content: center;
  }

  .user_contribution_bread_cr_sec_div {
    height: 5vh;
    width: 90%;
  }
  .user_contribution_join_div {
    display: none !important;
  }
  .landing_user_contribution {
    height: 112vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .correction_policy_container {
    height: auto;
    margin: 5vh 14vh;
    overflow-x: hidden;
  }
  .join_us_whatsapp_card {
    display: none;
  }
  .single-article-whatsapp-button-div {
    background-color: #00704a;
    height: 10vh;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 3%;
  }
  .my_fact_check_container {
    padding: 0 5% 0 7%;
  }
  .my_fact_check_table {
    padding: 2% 5% 0 7%;
  }
  .my_article_container {
    padding: 5% 5% 0 7%;
  }
  .single_article_whatsapp_channel {
    display: none;
  }
  .share_container {
    display: flex;
    gap: 10px;
  }
  .article_share_icon {
    height: 25px;
  }
  .article_share_icons {
    height: 20px;
  }
  .copy_to_clipboard_icon {
    height: 25px;
    cursor: pointer;
  }
  .reset_otp_box {
    margin-top: 2vh;
  }
  .resent_otp_text {
    color: #1d6758;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
  }
  .user_contribution {
    width: 100%;
    height: 39vh;
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    gap: 25px;
  }
  .donation-card-div_for_about_us {
    width: 68%;
    position: relative;
    margin-top: 6vh;
  }
  .public_spirited_image_div {
    display: none;
  }
  .public_spirited_image_image_and_text_div {
    height: 374px;
    display: flex;
    width: 66%;
    justify-content: center;
    flex-direction: column;
  }
  .team_members_profile_card {
    display: flex;
    width: 100%;
    margin-bottom: 70px;
    gap: 60px;
    padding: 15px;
  }

  .tamil-about-description {
    color: white !important;
    font-size: 17px !important;
  }
  .fact_check_rectangle_news {
    line-height: 21px !important;
  }
  .videos_play_button_icon {
    width: 32px !important;
    height: 32px !important;
    margin-right: 20px !important;
  }
  .subscribe_and_notification_icon_main_div {
    width: 93%;
    display: flex;
    justify-content: center;
  }
  .search_result_load_more_button {
    min-width: 300px !important  ;
    max-width: 500px !important;
  }
  .no_data_for_category_wise_article {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .custom_contributors_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6vh;
    margin-left: 42px;
  }
}
