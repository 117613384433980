@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .related_recent_fact_check_title_container {
    height: 50px;
  }

  .recent_fact_check_title_text {
    font-size: 13px !important;
    line-height: 19px !important;
  }
  .donation_first_row_content {
    font-size: 13px;
    line-height: 20px !important;
  }

  .whatsapp_channel_text {
    font-size: 15px;
    line-height: 20px;
  }

  .donation_first_row_content_en {
    font-size: 15px;
    line-height: 22px !important;
  }

  .whatsapp_channel_text_en {
    font-size: 15px;
    line-height: 23px;
  }

  .recent_fact_check_title_text_ta {
    font-size: 10px !important;
    line-height: 19px !important;
  }
  .reply_comment_text {
    font-size: 13px;
  }
  .single_fact_check_claim_container {
    position: sticky !important;
    top: 140px;
    bottom: 20px !important;
  }
}
