@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .related_recent_fact_check_container {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }
  .related_recent_fact_check_card_container {
    width: 100% !important;
    flex-direction: column !important;
  }
  .related_fact_check_banner {
    width: 100%;
  }
  .recent_fact_check_banner {
    width: 100;
    height: 100%;
  }
  .related_fact_check_container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;
    margin-bottom: 20px;
  }
  .recent_fact_check_banner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .recent_fact_check_title_text {
    font-size: 16px !important;
    line-height: 25px !important;
  }

  /* .recent_fact_check_title_wrapper {
    height: 100px;
  } */
  .home_user_contribution_two_profile {
    font-size: 18px;
    font-family: "Lato";
  }
  .single_fact_check_report_container {
    display: none !important;
  }
  .donation_secondary_banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .single_fact_check_claim_content_wrapper {
    flex-direction: column;
  }
  .single_fact_check_content_container {
    width: 100%;
  }
  .single_fact_check_whats_app_container {
    display: none;
  }
  .single_fact_claim_title,
  .single_fact_check_rating_container {
    align-items: start;
    justify-content: flex-start !important;
  }
  .secondary_donation_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 0px !important;
  }

  .recent_fact_check_title_text_ta {
    font-size: 15px !important;
    line-height: 25px !important;
  }
  .reply_comment_text {
    font-size: 13px;
  }
}
