.single_article_first_container {
  display: flex !important;
  justify-content: center !important;
  position: relative;
  background-color: #ffffff !important;
}
.single_article_top_details_wrapper {
  z-index: 2;
  position: relative;
  /* gap: 3%; */
  margin-top: 2%;
}
.single_article_title_report_share_save_container {
  width: 100%;
}
.single_article_back_ground_container {
  position: absolute;
  background-color: #140f2d;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 60%;
  z-index: 1;
}
.single_article_first_column_title {
  font-family: kufam;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.single_article_top_icons {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.single_article_top_icons_container {
  width: 100%;
  justify-content: flex-end !important;
  display: flex;
  gap: 3%;
  padding-bottom: 4%;
}
/* .single_article_writer_details { */
/* height: 40%; */
/* } */
.single_article_writer_details_container {
  height: 50%;
  display: flex;
  gap: 3%;
}
.writer_profile_image {
  width: 80px;
  height: 80px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover !important;
}
.single_article_writer_details_wrapper {
  display: flex;
  flex-direction: column;
  width: 80% !important;
  justify-content: space-between;
}
.writer_name {
  color: var(--subtitle-color);
  font-weight: 600;
}
.published_date {
  color: var(--subtitle-color);
}
.single_article_reading_time {
  color: var(--subtitle-color);
}
.single_article_view_count {
  color: var(--view-icon-color);
}
.single_article_view_and_reading_time {
  display: flex;
  gap: 7%;
}
.single_article_icons {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}
.report_share_container_mobile_view {
  border-top: 1px solid #f49d36;
  border-bottom: 1px solid #f49d36;
  display: flex;
  justify-content: space-between;
  padding: 3% 0 3% 0;
}
.report_share_first_column_container {
  display: flex;
  gap: 8%;
  width: 50%;
}
.report_share_second_column_container {
  display: flex;
  width: 20%;
  justify-content: space-between;
}
.single_article_details_with_donation_container {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.join_whatsapp_channel {
  background-color: #00704a;
  border-radius: 10px;
  padding: 1%;
  display: flex;
  padding: 2%;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}
.whatsapp_gif_icon {
  width: 100%;
}
.social_media_card_container {
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  background: var(--gradient-color);
  /* padding: 7% 0 7% 15%; */
}
.social_media_image_container {
  width: 50%;
  display: flex;
  gap: 10%;
  align-items: center;
}
.single_article_social_media_name {
  color: #ffffff;
  cursor: pointer;
}
.stay_connected_text {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}
.single_article_social_media_icon {
  width: 15%;
  cursor: pointer;
}
.social_media_card_wrapper {
  position: sticky !important;
  top: 110px;
  /* height: 16%; */
  min-height: 300px;
}
.single_article_details_with_donation_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 4% 0 0 0;
  margin-bottom: 40px;
  background-color: #ffffff;
}
.report_container {
  border: 1px solid black;
  width: 100%;
  border-radius: 20px;
  display: flex;
  gap: 5%;
  padding: 5% 5% 5% 5%;
  /* height: 100%; */
  flex-direction: column;
  justify-content: space-between;
}
.report_container_first_column {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.report_container_second_column {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5%;
}
.single_article_report_button {
  width: 100%;
  background-color: #e84756;
  color: #ffffff;
  border: none;
}
.single_article_report_button:hover {
  background-color: #e84756 !important;
  color: #ffffff !important;
}
.report_title {
  font-weight: 700;
  font-size: 25px;
}
.mick_image {
  width: 45%;
}
.mick_image_container {
  display: flex;
  justify-content: flex-end;
}

.share_save_container {
  display: flex;
  border-top: 1px solid #f49d36;
  border-bottom: 1px solid #f49d36;
  width: 100%;
  justify-content: space-between;
  padding: 2% 0 2% 0;
}
.share_save_container_second_column {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  gap: 5%;
}

.save_share_text {
  columns: var(--subtitle-color);
  cursor: pointer;
}
.save_share_icon {
  width: 20px;
  cursor: pointer;
}
.single_article_icons_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 20%;
}
.article_share_icon {
  height: 25px;
}
.article_share_icons {
  height: 20px;
}
.share_container {
  display: flex;
  gap: 10px;
}
.aligncenter {
  width: 100% !important;
  object-fit: contain !important;
  height: auto !important;
}
.single_article_content_container {
  display: flex;
  flex-direction: column;
}
.whatsapp_channel_content {
  color: #ffffff;
  width: 100%;
}
.copy_to_clipboard_icon {
  height: 25px;
  cursor: pointer;
}
.single_fact_check_mick_image_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.single_fact_check_mick_image {
  width: 100%;
}
.writer_details {
  width: 100%;
  margin: 10%;
}
.single_article_first_column_title_ta {
  font-family: kufam;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.article-content {
  text-align: justify !important;
  background-color: #ffffff !important;
}
.article-content img{
  width: 100%;
}
.video-content{
  width: 100%;
  height: auto;
}
