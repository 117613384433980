@media only screen and (min-width: 2000px) and (max-width: 2559px) {
  .single_article_first_column_title_ta {
    color: #ffffff;
    font-size: 38px;
    padding: 2% 0 0 0;
    height: 70%;
  }
  .single_article_top_icons {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .writer_profile_image {
    width: 90px !important;
    height: 90px !important;
  }
  .writer_profile_image {
    width: 120px !important;
    height: 120px !important;
  }
  .single_article_title_report_share_save_container {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    padding: 2% 0 2% 0;
  }
  .single_article_first_column_container {
    width: 35%;
  }
  .single_article_second_column_container {
    width: 70%;
  }
  .single_article_top_details_wrapper {
    width: 90%;
    display: flex !important;
    gap: 2%;
  }
  .report_share_container {
    display: none;
  }
  .single_article_writer_details {
    /* height: 40%; */
    display: flex;
    align-items: center;
  }
  .writer_name {
    font-size: 22px;
  }
  .published_date {
    font-size: 22px;
  }
  .single_article_icons {
    font-size: 20px;
  }
  .single_article_content_container {
    width: 60%;
    gap: 30px;
  }
  .single_article_donation_container {
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .social_media_card_container {
    gap: 25px;
    padding: 8% 0 8% 20%;
  }
  .stay_connected_text {
    font-size: 30px;
  }
  .single_article_social_media_icon {
    width: 20%;
    cursor: pointer;
  }

  .single_article_social_media_name {
    font-size: 20px;
  }
  .join_whatsapp_channel_division {
    display: none;
  }
  .whatsapp_channel_content {
    width: 100%;
    font-size: 25px;
  }
  .single_article_icons_wrapper {
    font-size: 20px;
  }
  .report_title {
    font-size: 40px;
  }
  .we_are_committed_text_ta {
    font-size: 22px;
  }
  .single_article_report_button {
    font-size: 22px;
  }
  .article-content {
    font-size: 25px;
  }
  .single_article_first_column_title {
    color: #ffffff;
    font-size: 32px;
    padding: 2% 0 0 0;
    height: 80%;
  }
  .we_are_committed_text {
    font-size: 25px;
  }
  .donation_card_mobile_view {
    display: none;
  }
}
